import {check_string} from "../support/precondition.js";

export function glob (pattern)
{
  check_string(pattern);

  return {
    'to_regex': () => {

      const len = pattern.length;




    }
  }
}