import {is_array} from "../support/util.js";

export function path_stack (path)
{
  return path.split('/')
}

export function relative_path (from, to)
{
  const min_len = Math.min(from.length, to.length)
  let same = 1

  const from_stack = is_array(from) ? from : path_stack(from)
  const to_stack = is_array(to) ? to : path_stack(to)

  for (; same < min_len && from_stack[same] == to_stack[same]; same++)
  {
  }

  const result = []
  for (let i = same; i < from_stack.length; i++)
  {
    result.push("..")
  }

  for (let i = same; i < to_stack.length; i++)
  {
    result.push(to_stack[i]);
  }

  return path(result)
}

function path (path_stack)
{
  return path_stack.join('/')
}