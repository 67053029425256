export function send (method, url, data, headers = {})
{
  const request = new XMLHttpRequest()

  return new Promise((resolve, reject) => {

    try
    {
      request.open(method, url, true);
    } catch (e)
    {
      reject(new Error('error opening xhr: ' + e.message))
    }

    request.setRequestHeader("X-Requested-With", "XMLHttpRequest")

    request.onreadystatechange = function () {
      if (request.readyState === XMLHttpRequest.DONE)
      {
        if (request.status >= 200 && request.status < 300)
        {
          resolve(request)
        }
        else
        {
          reject(new Error('' + request.status))
        }
      }
    }

    request.onerror = () => {
      reject(new Error('network error ' + url))
    }

    for (let k in headers)
    {
      const v = headers[k]
      if (v != null)
      {
        request.setRequestHeader(k, v);
      }
    }

    // TODO credentials
    // TODO mime type
    // TODO timeout handling
    // TODO if data is FormData

    try
    {
      request.send(data)
    } catch (e)
    {
      request.onreadystatechange = null
      reject(new Error('error sending xhr ' + e.message))
    }
  })
}