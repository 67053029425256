import {render} from "./index.js"

export function Tools ({children = []})
{
  //const valid = [Button, BackButton, SubmitButton]

  const tools = children.filter(child => child.node instanceof HTMLButtonElement)

  return render`
  <div class="ui menu row">
  ${tools.map(tool => render`${tool}`)}
  </div>
  `
}

export function Button ({children = [], onclick = null, href = '#', icon = null})
{
  let classes = []
  if (icon)
  {
    classes.push('primary')
    classes.push('icon')
    classes.push(icon)
  }

  classes = classes.join(' ')

  return render`
    <a onclick="${onclick}" class="${classes}">${children}</a>
  `
}

export function SubmitButton ({children})
{
  return render`
    <input type="submit">${children}</input>
  `
}

export function BackButton ({children})
{
  return render`
    <button onclick="alert('hallo');">${children}</button>
  `
}