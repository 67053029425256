import {render, state, Tabs, Tools} from "./index.js"
import {top} from "../support/array.js"

document.addEventListener("animationend", (e) => {
  const parent = e.target.closest('.transition')
  if (parent)
  {
    parent.classList.remove('transition')
  }
})

export function View ({children = [], title = ''})
{
  const toolbar_node = render`
    <div class="ui menu row">
      <span>${title}</span>
    </div>
  `

  const header = []
  const footer = []

  children = children.filter(child => {

    if (child.refs.includes(Tabs))
    {
      footer.push(child);
      return false;
    } else if (child.refs.includes(Tools))
    {
      header.push(child)
      return false;
    }

    return true;
  })

  //props.children = children

  let [views, update_views] = state(new Map)
  let [stack, update_stack] = state([])

  const nxt = {key: title, children}
  const cur = {key: stack[stack.length - 2], children: null}

  if (stack.length > 0 && top(stack) != nxt.key)
  {
    cur.key = top(stack)
  }

  if (cur.key)
  {
    cur.children = views.get(cur.key).children
  }


  //const cur = stack.length > 0 ? {key: top(stack), children: views.get(top(stack)).children} : null

  let clazz = ''

  update_views(views.set(nxt.key, {
    children,
    title
  }))

  if (nxt != null && stack[stack.length - 2] == nxt.key)
  {
    clazz = 'backward transition'
    stack = update_stack(stack.slice(0, -1))
  } else if (nxt != null && top(stack) != nxt.key)
  {
    clazz = 'forward transition'
    stack = update_stack(stack.concat([nxt.key]))
  }

  try
  {
    const node = render`
      <page title="${title}">
        <header>${header}</header>
        
        <style type="text/css">
          .panel.page {
            position: absolute;
            top:0;
            left: 0;
            width:100%;
            bottom:0;
          }
          
          .panel > div {
            position:absolute;left:0;top:0;bottom:0;right:0;
            overflow:scroll;
            -webkit-overflow-scrolling: touch;
          }
          
        </style>
        
        <main class="${clazz}" style="position: relative;">
          ${!cur.key ? '' : render`
          <div key="${cur.key}" class="ui default instant panel previous">
            <div>${cur.children}</div>
          </div>
          `}
          
          <div key="${nxt.key}" class="ui default instant panel current">
            <div>${nxt.children}</div>
          </div>
        </main>
        
        <footer>${footer}</footer>
      </page>
    `

    return node

  } finally
  {
    /*const next_previous = new_page_node.cloneNode(true)
    next_previous.key = new_page_node.key
    next_previous.classList.add('previous')
    next_previous.classList.remove('default')
    next_previous.classList.remove('current')
    update_previous_page(next_previous)*/
  }
}