export const empty_function_ref = function () {}

export const to_string_ref = ({}).toString;

const {isFrozen} = Object

const relative_url_regex = new RegExp('^(?:[a-z]+:)?//', 'i');
export function is_absolute_url (url)
{
	return relative_url_regex.test(url);
}

export function parse_url (url)
{
	if (url instanceof URL) {
		return url
	}

	if(!is_absolute_url(url)) {
		return new URL(url, window.location.href);
	}

	return new URL(url)
}

export function empty (e)
{
	return !!(!is_bool(e) && !is_number(e) && !e ||
	is_object(e) && Object.keys(e).length === 0 ||
	e.length && e.length === 0);
}

export function not_empty (e)
{
	return !empty(e)
}

export function global ()
{
	return window && window.window === window && window ||
	global && global.global === global && global
}

export function object_by_name (name, obj_opt)
{
	let parts = name.split('.')
	let cur = obj_opt
	for (var i = 0; i < parts.length; i++) {
		cur = cur[parts[i]]
		if (cur == null) {
			return null;
		}
	}
	return cur;
}

export function provide (name, object, overwrite, obj_to_export_to)
{
	let parts = name.split('.')
	let cur = obj_to_export_to || global();

	for (let part; parts.length && (part = parts.shift());) {
		if (!parts.length && object !== undefined) {
			if (!overwrite && is_object(object) && is_object(cur[part])) {
				for (let prop in object) {
					if (object.hasOwnProperty(prop)) {
						cur[part][prop] = object[prop];
					}
				}
			} else {
				cur[part] = object
			}
		} else if (cur[part] && cur[part] !== Object.prototype[part]) {
			cur = cur[part]
		} else {
			cur = cur[part] = {};
		}
	}
}

export function inherits (child, parent)
{
	function ctor () {}

	ctor.prototype = parent.prototype
	child.prototype = new ctor()
	child.prototype.constructor = child

	child.base = function (me, method) {
		var args = new Array(arguments.length - 2);
		for (var i = 2; i < arguments.length; i++)
			args[i - 2] = arguments[i];

		return parent.prototype[method].apply(me, args);
	}
}

export function is_defined (v)
{
	return v != null && typeof v !== 'undefined';
}

export const is_object = (e) => e instanceof Object;
export const is_array = (e) => type(e) === 'array';
export const is_bool = (e) => type(e) === 'boolean';
export const is_symbol = (e) => type(e) === 'symbol';
export const is_number = (e) => type(e) === 'number';
export const is_function = (e) => type(e) === 'function';
export const is_string = (e) => type(e) === 'string';

export function is_tagged (a, ...r)
{
	return is_array(a) && a.raw && isFrozen(a) && r.length + 1 === a.length
}

export function strcmp (a, b)
{
	a = a.toString()
	b = b.toString()
	let n, i = 0
	for (n = Math.max(a.length, b.length); i < n && a.charAt(i) === b.charAt(i); ++i) ;

	return i === n ? 0 : (a.charAt(i) > b.charAt(i) ? -1 : 1);
}

export function type (e)
{
	let r = to_string_ref.call(e);
	r = r.split(' ')[1].slice(0, -1).toLowerCase();
	return r;
}
