import {render, state} from "./index.js"
import {starts_with} from "../../../webui/js/text/string.js"
import {selection} from "../../../webui/js/dom/element.js"

function parse_hour (sub, sub0)
{
	if (parseInt(sub0, 10) > 2)
		sub = '0' + sub0
	else if (parseInt(sub, 10) > 24)
		sub = '24'

	return sub
}

function parse_minute (sub, sub0)
{
	if (parseInt(sub0, 10) > 5)
		sub = '0' + sub0
	else if (parseInt(sub, 10) > 59)
		sub = '00'

	return sub
}

function parse_day (sub, sub0)
{
	if (sub === '00') {
		sub = '01'
	} else if (parseInt(sub0, 10) > 3) {
		sub = '0' + sub0
	} else if (parseInt(sub, 10) > 31) {
		sub = '31'
	}

	return sub
}

function parse_month (sub, sub0)
{
	if (sub === '00') {
		sub = '01'
	} else if (parseInt(sub0, 10) > 1) {
		sub = '0' + sub0
	} else if (parseInt(sub, 10) > 12) {
		sub = '12'
	}

	return sub
}

function date_format ({
	value = '', pattern = ['D', 'M', 'Y'], delimiter = '.'
})
{
	let result = ''

	value = value.replace(/^[.]+/, '')
	value = value.replace(/[^\d.]/g, ''); // keep numbers and delimiters

	for (const i in pattern) {
		const block = pattern[i]
		//const len = block === 'Y' ? 4 : 2

		const block_len = block === 'Y' ? 4 : 2
		let val_block_len = value.indexOf('.') > -1 ? value.indexOf('.') : block_len
		//val_block_len = Math.max(val_block_len, block_len)

		let sub = value.slice(0, block_len)
		sub = sub.replace(/[^\d]/g, '')
		const sub0 = sub.slice(0, 1)

		let rest = value.slice(val_block_len)

		if ( 'D' === block )
			sub = parse_day(sub, sub0)
		else if ('M' === block)
			sub = parse_month(sub, sub0)
		else if ('H' === block)
			sub = parse_hour(sub, sub0)
		else if ('m' === block)
			sub = parse_minute(sub, sub0)

		result += sub

		if (starts_with(rest, '.')) {
			const i = rest.indexOf(delimiter) + 1
			result += rest.substr(0, i)
			rest = rest.substr(i)
		} else if (sub.length === block_len && i < pattern.length - 1) {
			result += delimiter
		}

		value = rest
	}

	return result
}

function format (type, value)
{
	switch (type) {
	case 'date':
		return date_format({value})
	case 'time':
		const pattern = ['H', 'm']
		const delimiter = ':'

		return date_format({value, pattern, delimiter})

	default:
		return value
	}
}

function next_caret_position (prev, old_val, new_val)
{
	if (old_val.length === prev) {
		return new_val.length
	}

	return prev + caret_offset(prev, old_val, new_val)
}

function caret_offset (prev, old_val, new_val)
{
	let old_raw_val, new_raw_val, len_offset

	old_raw_val = old_val.replace(/[^\d.]/g, '')
	new_raw_val = new_val/*.slice(0, prev)*/.replace(/[^\d.]/g, '')

	len_offset = new_raw_val.length - old_raw_val.length

	//alert("prev: " + prev + " offset " + len_offset + " old_raw_val " + old_raw_val + " new_raw_val " + new_raw_val);

	return len_offset

	//return (len_offset !== 0) ? (len_offset / Math.abs(len_offset)) : 0
}

export function input ({attrs} = {})
{
	const {value, type} = attrs

	let [val, update_val] = state(value || '')

	attrs.value = val

	let is_backspace = false
	let sel = {}

	const onkeydown = e => {
		var char_code = e.keyCode || e.which
		is_backspace = char_code == 8

		sel = {
			start: e.target.selectionStart,
			end: e.target.selectionEnd
		}

		return true
	}


	const onblur = e => {
		let new_val = e.target.value


		//update_value(format(new_value))

		return true
	}

	const onchange = e => {
		const old_val = val

		let new_val = e.target.value

		if (is_backspace && old_val.endsWith('.'))
			new_val = new_val.slice(0, val.length - 2)

		new_val = format(type, new_val)

		update_val(new_val)

		const pos = next_caret_position(sel.end, old_val, new_val)

		selection(e.target, pos)

		return true

	}

	const {label} = attrs

	const oninput = e => onchange(e)

	const attrs_to_filter = ['children', 'args', 'type', 'value']

	return render`
		<input type="text" value="${val}" ${attrs} 
			  autocomplete="off"
			  onblur="${onblur}" onkeydown="${onkeydown}" 
			  oninput="${oninput}">
  	`

	/*return render`

    <label class="control">
    	<span class="label">${label}</span>
    	<span>
			<input type="text" value="${val}" ${attrs} 
			  onblur="${onblur}" onkeydown="${onkeydown}" 
			  oninput="${oninput}">
			<span class="focus-ring"></span>
		</span>
	</label>
	`*/
}