import {render} from "./index.js"
import {is_html_form_element, walk_nodes} from "../dom/element.js";

export function Page ({children, title = ''})
{
  let type = ''
  for (const c in children)
  {
    if (!walk_nodes(c.node, n => is_html_form_element(n)))
    {
      type = 'form'
      break;
    }
  }

  switch (type)
  {
    case 'form':
      return render`
      <form title="${title}" class="viewport page">
        ${children}
      </form>
      `
      break

    default:
      return render`
      <div title="${title}" class="viewport page">
        ${children}
      </div>
      `
  }
}