import {is_array, is_string, is_symbol} from "../support/util.js";

export function starts_with (haystack, ...needles)
{
  for (const i in needles)
    if (haystack.lastIndexOf(needles[i], 0) == 0) return true

  return false
}

export function canonicalize_newlines (s)
{
  return s.replace(/(\r\n|\r|\n)/g, '\n')
}

export function is_breaking_whitespace (s)
{
  return !/[^\t\n\r ]/.test(s)
}

export function collapse_breaking_whitespace (s)
{
  return s.replace(/[\t\r\n ]+/g, ' ').replace(/^[\t\r\n ]+|[\t\r\n ]+$/g, '');
}

export function strcmp (s, other)
{
  s = to_string(s)
  other = to_string(other)
  return s < other ? -1 : +(s > other)
}

export function equals_ignoring_case (s, other)
{
  return s.localeCompare(other, undefined, {sensitivity: 'base'}) === 0
}

export const upper_first = s => s.charAt(0).toUpperCase() + s.slice(1)

export function case_styles (s)
{
  return [
    s, snake_case(s), upper_first(snake_case(s)),
    camel_case(s), pascal_case(s), kebab_case(s)
  ]
}

const INFINITY = 1 / 0
export const to_string = v => {
  if (v == null) return ''
  if (is_string(v)) return v

  if (is_array(v))
  {
    return `${v.map(o => o == null ? o : to_string(o))}`
  }

  if (is_symbol(v)) return v.toString()

  const r = `${v}`
  return (r == '0' && (1 / v) == -INFINITY) ? '-0' : r
}

export const regexp_escape =
  (s) => {
    return to_string(s).replace(/([-()\[\]{}+?*.$\^|,:#<!\\])/g, '\\$1').replace(/\x08/g, '\\x08')
  }

export const to_title_case =
  (s, opt_delimiters) => {
    let delimiters = (typeof opt_delimiters === 'string') ?
      regexp_escape(opt_delimiters) : '\\s';

    delimiters = delimiters ? '|[' + delimiters + ']+' : '';

    const regexp = new RegExp('(^' + delimiters + ')([a-z])', 'g');
    return s.replace(regexp, function (all, p1, p2) {
      return p1 + p2.toUpperCase();
    });
  }

const ascii_words = s => s.match(/[^\x00-\x2f\x3a-\x40\x5b-\x60\x7b-\x7f]+/g)

export const words = (s, pattern) => {
  if (pattern === undefined)
  {
    return ascii_words(s) || []
  }

  return s.match(pattern) || []
}

export const camel_case = (string) => {
  const result = words(to_string(string).replace(/['\u2019]/g, '')).reduce((r, w, i) => {
    w = w.toLowerCase()
    return r + (i ? upper_first(w) : w)
  }, '')

  return result
}

export const pascal_case = (s) => upper_first(camel_case(s))

export const kebab_case = (s) => {
  const result = words(to_string(s).replace(/['\u2019]/g, '')).reduce((r, w, i) => (
    r + (i ? '-' : '') + w.toLowerCase()
  ), '')

  return result
}

export const snake_case = (s) => String(s)
  .replace(/^[^A-Za-z0-9]*|[^A-Za-z0-9]*$/g, '')
  .replace(/([a-z])([A-Z])/g, (m, a, b) => a + '_' + b.toLowerCase())
  .replace(/[^A-Za-z0-9]+|_+/g, '_')
  .toLowerCase();

export const ltrim = (haystack, needle) => haystack.replace(RegExp('^' + needle), '')
