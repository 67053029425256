import {current_component_state, on_post_rendering, on_pre_rendering, to_node} from "./node.js";
import {merge} from "./merge.js";
import {set_render_depth} from "./render.js";

const hooks = new WeakMap

const hook_stack = []

function rerender (hook)
{
	const cur_node = hook.node
	const cur_comp = hook.comp
	const props = hook.props

	set_render_depth(2)
	try {
		hook_stack.push(hook)

		on_pre_rendering(cur_comp, props)

		const result = cur_comp(props)
		const new_node = to_node(result)
		on_post_rendering(new_node, props)

		merge({cur_node, new_node})

		hook.effects.forEach(effect => {
			effect()
		})
	} finally {
		set_render_depth(0)
	}

}

export function mount_component (comp, node)
{
	const hook = hooks.get(node)
	if (hook.comp == comp) {
		hook.node = node

		hook.effects.forEach(effect => {
			effect()
		})
	}
}

export function update_component (comp, node)
{

}

export function on_component_creating (comp, props)
{
	let cur_hook = hook_stack[hook_stack.length - 1]
	if (!cur_hook || cur_hook.comp !== comp) {
		cur_hook = {
			list: [], effects: [], node: undefined, comp, i: 0, e: 0, props
		}

		hook_stack.push(cur_hook)
	}

	cur_hook.creating = true
}

export function on_component_created (comp, node, props)
{
	const hook = hook_stack.pop()

	hook.props = props
	//hook.node = node
	hook.comp = comp
	hook.i = 0
	hook.e = 0
	hook.creating = false

	hooks.set(node, hook)
}

export function unmount_component (component)
{

}

function get_next_state (component, initial)
{

	const hook = hook_stack[hook_stack.length - 1]
	let i = hook.i++
	if (i >= hook.list.length) {

		const item = {
			value: initial,
			set_value: (new_value) =>
			{
				item.value = new_value

				if (!hook.creating) {
					window.requestAnimationFrame(() => rerender(hook))
				}

				return new_value

			},
		}

		hook.list.push(item)
	}

	return hook.list[i]

}

export function on_update (callback)
{
	const cur_comp = current_component_state()
	if (cur_comp) {
		const hook = hook_stack[hook_stack.length - 1]
		let e = hook.e++

		if (e >= hook.effects.length) {
			hook.effects.push(callback)
		} else {
			hook.effects[e] = callback
		}
	} else {
		throw new Error('illegal state error')
	}
}

export function state (initial)
{
	const cur_comp = current_component_state()
	if (cur_comp) {
		const state_obj = get_next_state(cur_comp, initial)
		return [state_obj.value, state_obj.set_value]
	}

	throw new Error('illegal state error')
}