import {is_string} from "../support/util.js";

const freeze = Object.freeze;

export function new_index_schema (name, field, unique)
{
  const m = {
    unique: unique,
    field: field || name,
    name: name
  }
  
  return {
    with_field (f)
    {
      m.field = f;
      return this;
    },
    
    is_unique: () => m.unique,
    
    unique (unique)
    {
      m.unique = unique;
      return this;
    },
  };
}

export function new_store_schema (name)
{
  const m = {
    id_key: "id",
    name: name,
    indexes: {}
  };
  
  return {
    
    get indexes () {return m.indexes},
    
    with_index (index)
    {
      index = is_string(index) ? new_index_schema(index) : index;
      m.indexes[index.name] = index;
      return this;
    }
  };
}

export function new_schema (version_num)
{
  let version = version_num || 1;
  let stores = {};
  
  return {
    
    get stores () {return stores;},
    set stores (def)
    {
      for (let name in def) {
        stores[name] = def[name];
      }
    },
    
    with_stores (...stores)
    {
      stores.forEach((s) => stores[s.name] = s);
      return this;
    },
    
    with_version (v)
    {
      version = v;
      return this;
    },
    
    version: () => version
  };
}