import {is_iterable} from "./iter.js";
import {is_number} from "./util.js";

export function default_comparator (a, b)
{
	return a > b ? 1 : a < b ? -1 : 0;
}

export function range (min, max)
{
	min = is_number(min) ? min : 0;
	!(is_number(max) && max > min) &&
	((max = min) & (min = 0));

	const arr = new (
	max < 128 ? Int8Array :
	max < 256 ? Uint8Array :
	max < 32768 ? Int16Array :
	max < 65536 ? Uint16Array :
	max < 2147483648 ? Int32Array :
	max < 4294967296 ? Uint32Array :
	Array)(max - min)

	for (let i = 0; i < arr.length; i++) arr[i] = i + min;
	return arr;
}

export function array (items)
{
	return is_iterable(items) ? [...items] : []
}

export function top (items)
{
	return items[items.length - 1]
}

export function binary_search (items, needle, comparator)
{
	let mid, r;
	comparator = comparator || default_comparator;
	let low = 0;
	let high = items.length;
	let found = false;

	while (low < high) {
		mid = low + (high - low >> 1);
		r = +comparator(items[mid], needle);
		if (r < 0.0) {
			low = mid + 1;
		} else {
			high = mid;
		}
	}

	return !r ? low : ~low;
}