import {new_tokenizer, types as tokenizer_event_types} from './tokenizer.js';
import {canonicalize_newlines} from "../text/string.js";

export const event_types = {
  OPEN: 'open',
  CLOSE: 'close',
  COMMENT: 'comment',
  COMMENT_IN_TAG: 'comment_in_tag',
  TEXT: 'text'
}

const new_set = ((comma_separated_text) =>
{
  return new Set(comma_separated_text.split(','))
})

const self_closing_set = new_set('br,img,link,input,area,base,col,command,embed,hr,keygen,meta,param,source,track,wbr')
const closed_by_parent = new_set('p,li,option,dd,rb,rt,rtc,rp,optgroup,tbody,tfoot,tr,td,th')

const is_self_closing_tag = (name) => self_closing_set.has(name)
const is_tag_closed_by_parent = (name) => closed_by_parent.has(name)


const closed_by = Object.freeze({
  p: new_set('address,blockquote,article,aside,div,dl,fieldset,form,h1,h2,h3,h4,h5,h6,header,hgroup,hr,footer,main,nav,ol,p,pre,section,table,ul'),
  option: new_set('option,optgroup'),
  optgroup: new_set('optgroup'),
  li: new_set('li'),
  dt: new_set('dt,dd'),
  dd: new_set('dt,dd'),
  rb: new_set('rb,rt,rtc,rp'),
  rt: new_set('rb,rt,rtc,rp'),
  rtc: new_set('rb,rtc,rp'),
  rp: new_set('rb,rt,rtc,rp'),
  thead: new_set('tbody,tfoot'),
  tbody: new_set('tbody,tfoot'),
  tfoot: new_set('tbody'),
  tr: new_set('tr'),
  td: new_set('td,th'),
  th: new_set('td,th')
});

const EMPTY_SET = new Set();

function is_tag_closed_by (prev_tag, cur_tag)
{
  return (closed_by[prev_tag] || EMPTY_SET).has(cur_tag)
}

function* parse (markup, start = 0, end = markup.length, eof = true)
{
  yield* new_parser().parse(markup, start, end, eof)
}

export function new_parser ()
{
  let cur_tag = {}, stack = new_stack(), tokenizer = new_tokenizer(), started = false

  function* parse (markup, start = 0, end = markup.length, eof = true)
  {
    for (const token_obj of tokenizer.tokenize(markup, start, end))
    {
      const {type, name, token, value} = token_obj

      switch (type)
      {
        case tokenizer_event_types.COMMENT:
        case tokenizer_event_types.COMMENT_IN_TAG:
          yield token_obj
          break
        case tokenizer_event_types.START:
          if (!started)
          {
            yield token_obj
            started = true
          }
          break
        case tokenizer_event_types.OPENING_TAG:
          cur_tag = {name, attrs: {}}
          break
        case tokenizer_event_types.CLOSING_TAG:
          const current = stack.peek()
          const parent = stack.peek(1)
          if (current)
          {
            if (current.name === name)
            {
              stack.pop()
              yield {type: event_types.CLOSE, attrs: current.attrs, name: current.name, self_closing: false}
            }
            else
            {
              if (parent && parent.name === name && is_tag_closed_by_parent(current.name))
              {
                stack.pop()
                yield {type: event_types.CLOSE, attrs: current.attrs, name: current.name, self_closing: false}
                stack.pop()
                yield {type: event_types.CLOSE, attrs: parent.attrs, name: parent.name, self_closing: false}
              }
            }
          }

          break

        case tokenizer_event_types.OPENING_TAG_END:
          const prev_tag = stack.peek()
          const self_closing = token === '/>' || is_self_closing_tag(name)

          if (prev_tag && is_tag_closed_by(prev_tag.name, cur_tag.name))
          {
            stack.pop()
            yield {type: event_types.CLOSE, attrs: prev_tag.attrs, name: prev_tag.name, self_closing: false};
          }

          yield {type: event_types.OPEN, name: cur_tag.name, attrs: cur_tag.attrs, self_closing}

          if (self_closing)
          {
            yield {type: event_types.CLOSE, attrs: cur_tag.attrs, name: cur_tag.name, self_closing};
          }
          else
          {
            stack.push(cur_tag)
          }

          break

        case tokenizer_event_types.TEXT:
          yield {type: event_types.TEXT, text: canonicalize_newlines(token_obj.text)}
          break
        case tokenizer_event_types.ATTRIBUTE:
          cur_tag.attrs[name] = value
          break
      }
    }

    if (eof)
    {
      while (stack.length > 0)
      {
        const prev = stack.pop()
        yield {type: event_types.CLOSE, name: prev.name, self_closing: false}
      }
      yield {type: tokenizer_event_types.DONE}
      started = false
    }
  }

  return {parse}
}

const new_stack = (() =>
{

  function peek (n = 0)
  {
    return this[this.length - (n + 1)]
  }

  return () =>
  {
    const stack = []
    stack.peek = peek
    return stack
  }
})()

export {parse}