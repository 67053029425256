import {render2} from "../../../webui/js/dom/render.js"
import {is_object} from "../../../webui/js/support/util.js"
import {input} from "./input.js"
/*import {calendar} from "./calendar.js"
import {scroll} from "./scroll.js"*/
import {emplace_flow_api} from "../../../webui/js/dom/flow.js"

emplace_flow_api()

export * from '../../../webui/js/ui/index.js'

/*export * from '../../../webui/js/dom/hook.js'*/
export * from '../../../webui/js/support/array.js'
export * from '../../../webui/js/support/iter.js'
export * from '../../../webui/js/dom/element.js'

export function render (arg1, ...rest)
{
	const markup = {
		'ui-input': input,
		/*'ui-calendar': calendar,
		'ui-scroll': scroll,*/
	}

	return (s_arg1, ...s_rest) =>
	{
		const args = [s_arg1, ...s_rest]
		const dict = is_object(args[args.length - 1]) ? args[args.length - 1] : {}

		args[args.length - 1] = Object.assign(dict, markup)

		return render2(arg1, ...rest)(...args)
	}
}