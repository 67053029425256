import {each, range, render} from "./index.js"

function days_in_month (month, year)
{
	const d = new Date(year, month, 0)
	return d.getDate()
}

function month_view ()
{
	const date = new Date()

	const year = date.getFullYear()
	const month = date.getMonth()
	const days = days_in_month(month, year)
	var day_of_week = date.getDay()


	//const a = range(30)

	function link(item) {
		return render`
         <li><a href="loadx:${item.page}">${item.title}</a></li>
        `
	}

	function item (i)
	{
		return render`
			<span>${i}</span>
		`
	}

	const links = [
		{title: 'x', page: 'x'},
		{title: 'y', page: 'y'},
	]


	return render`	
		<section>
			<header>
				<ul>
					<li>SUN
					<li>MON
					<li>TUE
					<li>WED
					<li>THU
					<li>FRI
					<li>SAT
				</ul>
			</header>
			
			<ul>
				${links.map(i => link(i))}
			</ul>
		
		</section>
	`
}

export function calendar ()
{
	return month_view()
}