import {is_element, is_node, position} from "./element.js"
import {is_function, is_number, is_object, is_tagged} from "../support/util.js"
import {$ as N$, args_stack, node, refs_map, to_node} from "./node.js"
import {merge, NODE_APPENDING, NODE_ENTERING, NODE_LEAVING, NODE_VISITING} from "./merge.js"
import {mount_component, update_component} from "./hook.js";
import {array, top} from "../support/array.js";

export function render2 (arg1, ...rest)
{
	return (s_arg1, ...s_rest) =>
	{
		let dict = {}
		let args = []
		if (s_arg1) {
			args = [s_arg1, ...s_rest]
			dict = is_object(args[args.length - 1]) ? args[args.length - 1] : {}
			args_stack.push({args, dict})
		}

		const compiled = []
		rest.forEach((key, i) =>
		{
			let val = key
			if (is_number(key)) {
				val = args[key]
			} else if (!is_function(key)) {
				val = dict[key]
			}

			if (val === dict) {
				val = rest[i]
			}

			compiled.push(val ? val : rest[i])
		})

		try {
			return render_impl(arg1, dict, ...compiled)
		} finally {
			if (s_arg1) {
				args_stack.pop()
			}
		}
	}
}

export function render (arg1, ...rest)
{
	if (is_tagged(arg1, ...rest)) {
		const dict = (args_stack.length > 0 ? args_stack[args_stack.length - 1] : {}).dict || {}
		//const dict = Object.assign(markup, d)
		return () => render_impl(arg1, dict || {}, ...rest)
	} else if (is_node(arg1)) {
		return render_node(arg1)
	} else {
		const args = [arg1, ...rest]
		const dict = is_object(args[args.length - 1]) ? args[args.length - 1] : {}
		//const dict = Object.assign(markup, args[args.length - 1] || {})
		args_stack.push({args, dict})
		return (items, ...exprs) =>
		{
			const compiled = []
			exprs.forEach((key, i) =>
			{
				let value = is_number(key) ? args[key] : dict[key]
				if (value === dict) {
					value = exprs[i]
				}

				compiled.push(value ? value : exprs[i])
			})

			try {
				return render_impl(items, dict, ...compiled)
			} finally {
				args_stack.pop()
			}
		}
	}
}

let render_depth = 0

export function set_render_depth (d)
{
	render_depth = d
}

function render_impl (items, dict, ...exprs)
{
	render_depth++
	try {

		const components = new Map
		const on_create_node = (node, component) =>
		{
			if (component) {
				components.set(node, component)
			}
			return node
		}

		let new_node = items
		if (is_function(items)) {
			new_node = to_node(items)
		}

		if (!is_node(new_node)) {
			new_node = node({dict, node: on_create_node})(items, ...exprs)
		} else {
			on_create_node(new_node, items)
		}

		return render_node(new_node, {dict, components})
	} finally {
		render_depth--
	}
}

export function render_node (new_node, {
	dict = {}, components = new Map, parent = document.body, depth = render_depth
})
{
	while (is_function(new_node)) {
		new_node = new_node(dict)
	}

	switch (depth) {
	case 1:

		if (!parent) {  // defer rendering if no parent passed and body element received
			window.arrive('body', () => render_node(new_node, {dict, components, parent: document.body, depth}))
		} else {
			const id = new_node.id
			const pos = position(new_node)
			let cur_node = dict.target_node
			if (!cur_node && pos < array(parent.children).length) {
				cur_node = array(parent.children)[pos]
			} else if (!cur_node) {
				cur_node = document.createElement('t')
				parent.appendChild(cur_node)
			}

			if (id) {
				const old_node_by_id = document.getElementById(id)
				if (is_element(old_node_by_id)) {
					cur_node = old_node_by_id
				}
			}

			const component_stack = []

			console.log('start merging')

			const node = (node, i) => {
				const component = components.get(node)
				switch (i) {
				case NODE_ENTERING:
					if (component) {
						component_stack.push({component, update: false})
					}
					break
				case NODE_LEAVING:
					if (component) {
						const c = component_stack.pop()
						if (c.update) {
							update_component(c.component, node)
						}
					}
					break

				case NODE_VISITING:
					break
				default:

					if (top(component_stack)) {
						top(component_stack).update = true
					}

					// mutation
					if (i === NODE_APPENDING && components.has(node)) {
						// append node
						const component = components.get(node)
						mount_component(component, node)
					}

					break;
				}

				return node
			}


			merge({cur_node, new_node, node})

			refs_map.clear() // TODO any idea to not use a singleton?

			return cur_node
		}
	}
	return new_node

}

export const $ = N$
