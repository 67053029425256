import "../dom/flow.js";
import {reflow} from "../dom/element.js";
import {camel_case} from "../text/string.js";
import {empty} from "../support/util.js";
import {NODE_UPDATE_ATTRIBUTE, NODE_UPDATE_ATTRIBUTES, NODE_UPDATED_ATTRIBUTES} from "../dom/merge.js";

let modal_toggle_data_attribute = 'modalToggle';
let modal_toggle_name = 'modal-toggle';
let modal_toggle_selector = '[data-modal-toggle]';
let modal_selector = '.modal';
//let modal_wrapper_selector = '.modal-wrapper';
let overflow_hidden_class = 'overflow-hidden';
let modal_active_class = 'modal-active';
let modal_active_selector = '.modal-active';
let modal_target_selector = '.modal:target';
let modal_body_selector = '.modal-body';

let prefix = '';
let prefix_ = '';

//let active_modal_count = 0;

const active_modals = new Set

export function modal_merge_callback (node, type, cur_node, name, old_val, new_val)
{
	switch (type) {
	case NODE_UPDATE_ATTRIBUTE:
		if ('style' === name) {
			if ((document.body.isSameNode(cur_node) ||
			node.matches(modal_selector) && cur_node.matches(modal_selector)))
			{
				return false
			}
		} else if('class' === name) {
			if((document.documentElement.isSameNode(cur_node))) {
				return false;
			}
		}

		break;

	case NODE_UPDATED_ATTRIBUTES:
		// after updating attributes calling modal lifecycle hooks
		if (cur_node.matches(modal_selector)) {
			if (active_modals.has(cur_node)) {
				on_modal_activated(cur_node)
			}
			else {
				on_modal_deactivated(cur_node)
			}
		}
	case NODE_UPDATE_ATTRIBUTES:
		// before updating attributes: will modal activated or not?
		if (cur_node.matches(modal_body_selector)) {
			// HEADS UP: do not merge any attributes e.g. style if active state
			return !cur_node.closest(modal_selector).matches(modal_active_selector) &&
        !cur_node.closest(modal_selector).matches(modal_target_selector);
		}
		else if (cur_node.matches(modal_selector)) {
			if (cur_node.matches(modal_active_selector) && !node.matches(modal_active_selector))
			{ // deactivate by removing modal-active class
				if('true' === cur_node.dataset.modalActive) {
					return false;
				} else if (active_modals.has(cur_node)) {
					active_modals.delete(cur_node);
					console.log("SHOULD BE CLOSED");
				}
				return true;
			}
			else if (!cur_node.matches(modal_active_selector) && node.matches(modal_active_selector)) {
				active_modals.add(cur_node)
			} else if(cur_node.matches(modal_target_selector) && !active_modals.has(cur_node)) {
			  active_modals.add(cur_node)
      }
		}

		break;
	}

	return node;
}

export function install_modal (p)
{
	modal_toggle_data_attribute = 'modalToggle';
	modal_toggle_name = 'modal-toggle';
	modal_toggle_selector = '[data-modal-toggle]';
	modal_selector = '.modal';
	overflow_hidden_class = 'overflow-hidden';
	modal_active_class = 'modal-active';
	modal_active_selector = '.modal-active';
  modal_target_selector = '.modal:target';
	modal_body_selector = '.modal-body';


	prefix = p || '';
	prefix = prefix.toLowerCase();
	prefix_ = !empty(prefix) ? `${prefix}-` : '';

	console.log(modal_toggle_name)

	modal_toggle_name = `${prefix_}${modal_toggle_name}`;
	modal_selector = `.${prefix_}modal`;
	modal_body_selector = `.${prefix_}modal-body`;
	modal_active_class = `${prefix_}${modal_active_class}`;
	modal_active_selector = `.${modal_active_class}`;
  modal_target_selector = `.${prefix_}modal:target`;
	overflow_hidden_class = `${prefix_}${overflow_hidden_class}`;

	modal_toggle_data_attribute = camel_case(modal_toggle_name);
	modal_toggle_selector = `[data-${modal_toggle_name}]`;

	console.log('adding arrive listener for ' + modal_toggle_selector);

  window.addEventListener("hashchange", on_hash_change, false);

  if(is_selector_valid(window.location.hash)) {
    window.arrive(window.location.hash, node => {
      on_hash_change();
    });
  }

	window.arrive(modal_toggle_selector, node =>
	{
		console.log("adding click listener");

		node.removeEventListener('click', on_click, {passive: true});
		node.addEventListener('click', on_click, {passive: true});
	})

	window.arrive(modal_selector, node =>
	{
		// first enable pointer events and scrolling for modal-wrappers
		// arriving dom in an activated state
		if (enable_pointer_events_for_modal_if_active(node))
		{
			const scrollable_node = modal_scrollable_for(node)

			disable_page_scrolling(true, scrollable_node);
		}
		install_animation_listeners(node);
	});
}


function install_animation_listeners (node)
{
	// install modal animation listeners for modal-wrapper
	node.removeEventListener('transitionend', on_modal_animation_end);
	node.removeEventListener('transitioncancel', on_modal_animation_end);
	node.removeEventListener('transitionrun', on_modal_animation_run);
	node.addEventListener('transitionend', on_modal_animation_end);
	node.addEventListener('transitioncancel', on_modal_animation_end);
	node.addEventListener('transitionrun', on_modal_animation_run);
}

function enable_pointer_events_for_modal_if_active (wrapper, force)
{
	console.log("contains? " + modal_active_class);

	if (force || wrapper.classList.contains(modal_active_class) ||
    wrapper.matches(modal_target_selector)) {

		active_modals.add(wrapper);

		console.log("contains! " + modal_active_class);

		//wrapper.style['pointer-events'] = 'auto'
		//wrapper.style['overflow-y'] = 'auto'

		//reflow(wrapper)

		return true;
	}

	return false;
}

function disable_pointer_events_for_modal_wrapper (wrapper)
{
	if (!wrapper.closest(modal_active_selector) &&
    !wrapper.closest(modal_target_selector)) {

		wrapper.style.removeProperty('overflow-y');
		 wrapper.style.removeProperty('pointer-events');

		if (active_modals.has(wrapper)) {
			active_modals.delete(wrapper);
		}

		return true;
	}

	return false;

}

function on_modal_animation_end (event)
{
	const wrapper = event.target.closest(modal_active_selector) ||
    event.target.closest(modal_target_selector);

	if (wrapper) {
		/*wrapper.style['overflow-y'] = 'auto';
		 wrapper.style['pointer-events'] = 'auto';*/

		reflow(wrapper)
	}
}

function on_modal_activated (wrapper, force)
{
	console.log('on modal activated ' + (wrapper.getAttribute('id') || wrapper.getAttribute('class')));

	if (enable_pointer_events_for_modal_if_active(wrapper, force)) {
		const scrollable_node = modal_scrollable_for(wrapper)
		disable_page_scrolling(true, scrollable_node);

		return true;
	}

	return false;
}

function on_modal_animation_run (event)
{
	const wrapper = event.target.closest(modal_selector);
	if (wrapper)
	{
		//wrapper.style['overflow'] = 'hidden'
	}

	// disable scrolling and pointer events for modal if any animation is running
	/*const wrapper = event.target.closest(modal_selector);
	 if (wrapper) {
	 disable_page_scrolling(true, wrapper)
	 disable_pointer_events_for_modal_wrapper(wrapper);
	 }*/
}

function modal_scrollable_for (wrapper)
{
	return wrapper
}

function on_modal_deactivated (wrapper)
{
	console.log('on modal deactivated ' + wrapper.getAttribute('id'))
	if (disable_pointer_events_for_modal_wrapper(wrapper)) {
		const scrollable_node = modal_scrollable_for(wrapper)
		disable_page_scrolling(false, scrollable_node)
	}
}

const disable_page_scrolling = (function ()
{
	let element = false, client_y, on_move_body_enabled = false

	function on_move_body (event)
	{
		/*if (false === element || !event.target.closest(modal_selector)) {
		 event.preventDefault();
		 }*/

		if (false !== element && active_modals.size > 0) {

			console.log("on_move_body preventDefault")

			//event.preventDefault();
		}
	}

	function on_touch_start_modal (event)
	{
		if (event.targetTouches.length === 1) {
			client_y = event.targetTouches[0].clientY;
		}
	}

	function on_touch_move_modal (event)
	{
		if (event.targetTouches.length !== 1)
		{ // only respond to a single touch
			//return;
		}

		const _client_y = event.targetTouches[0].clientY - client_y;
		if (element.scrollTop === 0 && _client_y > 0)
		{ // The element at the top of its scroll
			// and the user scrolls down
			//event.preventDefault();
		}

		// The element at the bottom of its scroll,
		// and the user scrolls up
		// https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollHeight#Problems_and_solutions
		if ((element.scrollHeight - element.scrollTop <= element.clientHeight) && _client_y < 0) {
			//event.preventDefault();
		}
	}

	return function (allow, modal_scrollable_element)
	{
		if (typeof modal_scrollable_element !== undefined) {
			element = modal_scrollable_element;
		}

		if (true === allow) {

			console.log("disable page scroll" + active_modals.size)


			if (false !== element) {
				element.addEventListener('touchstart', on_touch_start_modal, {passive: false});
				element.addEventListener('touchmove', on_touch_move_modal, {passive: false});
			}

			if (!on_move_body_enabled) {

				console.log("disable page scrolling styles")


				document.documentElement.classList.add(`${prefix_}scroll-disable`)

				document.body.addEventListener("touchmove", on_move_body, {passive: false});
				on_move_body_enabled = true;
			}
		}
		else if (false !== element)
		{
			console.log("enable page scroll " + active_modals.size)

			if (element) {
				element.removeEventListener('touchstart', on_touch_start_modal, {passive: false});
				element.removeEventListener('touchmove', on_touch_move_modal, {passive: false});
			}

			if (active_modals.size === 0) {
				console.log("enable page scrolling styles")

				document.documentElement.classList.remove(`${prefix_}scroll-disable`)

				document.body.removeEventListener("touchmove", on_move_body, {passive: false});
				on_move_body_enabled = false;
			}
		}
	}
})();

const query_check = s => document.createDocumentFragment().querySelector(s)
const is_selector_valid = selector => {
  try { query_check(selector) } catch { return false }
  return true
}

function on_hash_change ()
{
  const hash = location.hash

  if(is_selector_valid(hash)) {
    active_modals.forEach(node => {
      // on location.hash transition first deactivate any none-active modal
      if(!node.matches(modal_active_selector) && !node.matches(modal_target_selector)) {
        on_modal_deactivated(node)
      }
    })

    const target = document.querySelector(hash)
    if(target && target.matches(modal_selector))
    {
      /**
       * HEADS UP:
       * second argument is true to force modal activation because
       * on_hash_change() will be called on page reload and in this case the
       * modal_target_selector is not matching true
       */
      on_modal_activated(target, true)
    }
  }
}

function on_click (event)
{
	console.log("handle click " + modal_toggle_selector);

	const e = event.target.closest(modal_toggle_selector)
	if (e) {

		event.preventDefault()

		const id = e.dataset[modal_toggle_data_attribute];

		console.log("search for " + id);

		const target = document.getElementById(id);

		//const wrapper = target.querySelector(modal_selector);

		if (target.classList.toggle(modal_active_class)) {
			// enable modal
			on_modal_activated(target);

			target.dataset.modalActive = true;

		}
		else {
			on_modal_deactivated(target);

			target.dataset.modalActive = false;

			// disable modal
			// reset the scroll position, otherwise modals
			// will keep their scroll position when opened next time
			if (target) {
				target.scrollTop = 0;
			}
		}
	}

	return true;
}