export function Index (name)
{
  const query = (op) => function () {
    return IndexQuery(name, op, arguments);
  };

  return {
    eq: query('eq')
  }
}

const Request = () => ({
  result: undefined,
  onsuccess: null,
  onerror: null,
  readyState: "pending"
});

function notify_success (request, result)
{
  request.readyState = "done";
  request.result = result;
  if (typeof request.onsuccess == "function")
  {
    request.onsuccess({
      type: "success",
      target: request
    });
  }
}

function ResultRequest (store, query_fn, only_keys)
{
  const req = Request();

  query_fn(store, (keys) => {
    const result = [];
    const next = () => {
      const r = store.get(keys.shift());
      r.onsuccess = () => {
        result.push(r.result);
        if (!keys.length)
        {
          notify_success(req, result);
          return;
        }

        next();
      };
    };

    next();
  });

  return req;
}

const Query = (query_fn) => {

  const result = (store, callback) => query_fn(store, callback);

  result.all = (store) => ResultRequest(store, query_fn);
  result.and = (query2) => IntersectionQuery(query_fn, query2);

  return result;
};

const IntersectionQuery = (query1, query2) => Query((store, callback) => {
  query1(store, (keys1) => {
    query2(store, (keys2) => {
      callback(array_intersect(keys1, keys2));
    })
  })
});

function IndexQuery (name, op, items)
{
  function key_range (op)
  {
    switch (op)
    {
      case "eq":
        return IDBKeyRange.only(items[0])
    }
  }

  function query_keys (store, callback)
  {
    const index = store.index(name);
    const range = key_range(op);
    const request = index.getAllKeys(range);

    request.onsuccess = () => callback(request.result);
  }

  return Query(query_keys);
}

function array_intersect (a1, a2)
{
  if (!a1.length) return a1;
  if (!a2.length) return a2;
  return a1.filter((item) => a2.indexOf(item) != -1);
}