import {empty_function_ref, is_bool, is_function, is_string} from '../support/util.js'
import {get_property, INLINE_EVENT_PROPERTIES, is_element} from "./element.js";

export const NODE = (n, operation, other) => {

  if (operation === NODE_ENTERING)
  {
    /*if (is_svg_element(n) && is_svg_element(other))
     {
     console.log("visit two svg elements")


     return false;
     }*/
  }

  return n;
};

export function merge ({cur_node, new_node, compare = COMPARE, node = NODE})
{
  const args = [].slice.call(arguments)
  cur_node = cur_node || document.body

  if (is_string(args[0]))
  {
    const dom = document.implementation.createHTMLDocument("");
    dom.documentElement.innerHTML = args[0]
    new_node = dom.body
  }

  const cur_root = cur_node.parentNode || cur_node, new_root = new_node.parentNode || new_node

  if (cur_root !== cur_node)
  {
    [cur_node] = merge_nodes({
      compare, parent: cur_root, current: [cur_node], future: [new_node], node
    })
  }

  if (cur_node === new_node) return false
  let result = false
  while (cur_node != null && new_node != null)
  {
    if (!transient(new_node))
    {

      if (node(new_node, NODE_ENTERING, cur_node) !== false)
      {


        const r = merge_nodes({
          parent: cur_node,
          current: [...cur_node.childNodes],
          future: [...new_node.childNodes], node
        })

        if (!result && r && r.length > 0)
        {
          result = true
        }

        if (update_attributes(cur_node, new_node, node))
        {
          //console.log("update attr occured " + cur_node.tagName)
        }
      }
    }

    if (cur_node.hasChildNodes() && new_node.hasChildNodes())
    {
      cur_node = cur_node.firstChild
      new_node = new_node.firstChild
    }
    else
    {
      while (cur_node.nextSibling == null && cur_node !== cur_root &&
      new_node.nextSibling == null && new_node !== new_root)
      {
        cur_node = cur_node.parentNode
        new_node = new_node.parentNode

        node(new_node, NODE_LEAVING, cur_node)
      }

      if (new_node === new_root || cur_node === cur_root) break

      cur_node = cur_node.nextSibling
      new_node = new_node.nextSibling
    }
  }

  return result
}

const SKIP = 0
const INSERT = 1
const DELETE = -1

function apply_diff ({diff, parent, c, n, node, before})
{
  const len = diff.length
  let i = 0
  while (i < len)
  {
    switch (diff[i++])
    {
      case SKIP:
        n.start++
        c.start++
        break
      case INSERT:
        append(parent, n.nodes, n.start++, n.start, {node_before: c.start < c.len ? c.nodes[c.start] : before, node})
        break
      case DELETE:
        remove(c.nodes, c.start++, c.start, {node})
        break
    }
  }

  return [...parent.childNodes]
}

function myers_diff ({c, n, compare})
{
  const rows = n.changes
  const cols = c.changes
  const len = rows + cols

  let d, k, r, i, pv, cv, pd
  const v = []
  o: for (d = 0; d <= len; d++)
  {
    pd = d - 1
    pv = d ? v[d - 1] : [0, 0]
    cv = v[d] = []

    for (k = -d; k <= d; k += 2)
    {
      if (k === -d || (k !== d && pv[pd + k - 1] < pv[pd + k + 1]))
      {
        i = pv[pd + k + 1];
      }
      else
      {
        i = pv[pd + k - 1] + 1;
      }
      r = i - k;

      while (i < cols && r < rows && compare(c.nodes[c.start + i], n.nodes[n.start + r]))
      {
        i++;
        r++;
      }

      if (i === cols && r === rows) break o;

      cv[d + k] = i
    }
  }
  const size = d / 2 + len / 2
  const diff = Array(size)
  let diff_idx = diff.length - 1
  for (d = v.length - 1; d >= 0; d--)
  {
    while (i > 0 && r > 0 && compare(c.nodes[c.start + i - 1], n.nodes[n.start + r - 1]))
    {
      diff[diff_idx--] = SKIP
      i--
      r--
    }

    if (!d) break

    pd = d - 1
    pv = d ? v[d - 1] : [0, 0]
    k = i - r
    if (k === -d || (k !== d && pv[pd + k - 1] < pv[pd + k + 1]))
    {
      r--
      diff[diff_idx--] = INSERT
    }
    else
    {
      i--
      diff[diff_idx--] = DELETE
    }
  }

  return diff
}

function node_key (n, k)
{
  return !n || !k ? void 0 : is_string(k) ? n[k] : k(n)
}

function keys (nodes, key = 'key')
{
  const index = {}, keys = []
  let i = 0
  while (i < nodes.length)
  {

    const n = nodes[i]
    const k = node_key(n, key)
    if (k)
    {
      keys.push(k)
      index[k] = i
    }

    i++
  }

  return {index, keys}
}

function array_move (arr, from, to)
{
  var e = arr[from];
  arr.splice(from, 1);
  arr.splice(to, 0, e);
}

export const NODE_APPENDING = 1
export const NODE_REMOVING = -1
export const NODE_VISITING = 0
export const NODE_ENTERING = 2
export const NODE_LEAVING = 3
export const NODE_UPDATE_ATTRIBUTES = 4
export const NODE_UPDATE_ATTRIBUTE = 5
export const NODE_UPDATED_ATTRIBUTES = 6


export function merge_nodes ({parent, current = [], future = [], compare = COMPARE, before, node})
{
  const local_compare = (c, n) => compare(c, node(n, NODE_VISITING, c))

  const c_len = current.length, n_len = future.length

  const c = {start: 0, end: c_len, len: c_len, same: false, changes: 0, nodes: current, keys: keys(current)}
  const n = {start: 0, end: n_len, len: n_len, same: false, changes: 0, nodes: future, keys: keys(future)}

  c.keys.keys.forEach((key) => {  // reordering by nodes key
    const old_pos = c.keys.index[key]
    const new_pos = n.keys.index[key]
    if (old_pos && old_pos !== new_pos && new_pos < c.len)
    {
      parent.insertBefore(c.nodes[old_pos], c.nodes[new_pos])
      array_move(c.nodes, old_pos, new_pos)
    }
  })

  while (c.start < c.end && n.start < n.end && local_compare(c.nodes[c.start], n.nodes[n.start]))
  { // common head
    c.start++
    n.start++
  }

  while (c.start < c.end && n.start < n.end && local_compare(c.nodes[c.end - 1], n.nodes[n.end - 1]))
  { // common tail
    c.end--
    n.end--
  }

  if ((c.same = c.start === c.end) && (n.same = n.start === n.end))
  {
    return current
  } // same lists

  if (c.same && n.start < n.end)
  { // only append
    return current.concat(append(parent, n.nodes, n.start, n.end,
      {node, node_before: next(c.nodes, c.start, c.len, before)}))
  }

  if (n.same && n.start < n.end)
  { // only remove
    remove(c.nodes, c.start, c.end, {node})
    c.nodes.splice(c.start, c.end)
    return c.nodes
  }

  c.changes = c.end - c.start
  n.changes = n.end - n.start

  if (c.changes < 2 || n.changes < 2)
  { // one replacement
    const new_nodes = append(parent, n.nodes, n.start, n.end, {node_before: c.nodes[c.start], node})
    remove(c.nodes, c.start, c.end, {node})

    c.nodes.splice(c.start, c.end, ...new_nodes)
    return c.nodes
  }

  //return simple_merge({parent, c, n, node, compare})
  return efficient_merge({
    parent, c, n, node,
    compare: local_compare, before
  })
}

function efficient_merge ({parent, c, n, node, compare, before})
{
  const diff = myers_diff({c, n, compare})
  return apply_diff({diff, parent, c, n, node, before})
}


function simple_merge ({parent, c, n, node, compare})
{
  while (c.start < c.len || n.start < n.len)
  {
    const cur_node = c.nodes[c.start]
    const new_node = n.nodes[n.start]

    if (!cur_node)
    {
      append(parent, n.nodes, n.start, n.start + 1, {node_before: c.nodes[c.start], node})
    }
    else if (!new_node)
    {
      remove(c.nodes, c.start, c.start + 1, {node})
    }
    else if (!compare(cur_node, new_node))
    {
      append(parent, n.nodes, n.start, n.start + 1, {node_before: c.nodes[c.start], node})
      remove(c.nodes, c.start, c.start + 1, {node})
    }

    c.start++
    n.start++
  }

  return [...parent.childNodes]
}

function to_node_name_set (named_node_map, set = new Set)
{
  if (named_node_map)
  {
    for (let i = 0; i < named_node_map.length; ++i)
    {
      set.add(named_node_map[i].nodeName)
    }
  }

  return set;
}

function attr_val (attr)
{
  return attr != null ? attr.nodeValue : null
}

function property_or_attr_val (e, name)
{
  return get_property(e, name)
}

function update_attribute (n, new_node, name, old_val, new_val, node_callback)
{
  if (node_callback(new_node, NODE_UPDATE_ATTRIBUTE, n, name, old_val, new_val))
  {
    if (true /*!is_svg_element(n)*/)
    {
      if (new_val == null)
      { // undefined or null
        remove_attribute(n, new_node, name, old_val);
        return true;
      }
      else /*if (old_val == null || new_val !== old_val)*/
      {
        set_attribute(n, name, new_val);
        return true
      }
    }
  }

  return false
}

function is_boolean_attribute (name, value)
{
  return typeof value === 'boolean' ||
    ['selected', 'checked', 'disabled', 'hidden', 'open'].indexOf(name) >= 0
}

function remove_attribute (target, new_node, name, value)
{
  if (is_boolean_attribute(name, value))
  {
    remove_boolean_attribute(target, name);
  }
  else
  {
    target.removeAttribute(name);
    if (is_function(target[name]))
    {
      target[name] = empty_function_ref
    }
  }
}

function remove_boolean_attribute (target, name)
{
  switch (name)
  {
    case 'open':
      // it is not allowed to remove the open attribute
      break;
    default:
      target.removeAttribute(name);
      target[name] = false;
  }
}

function set_string_attribute (target, name, value)
{
  const is_focused = document.activeElement === target;
  const old_val = target.getAttribute(name);
  if (strcmp(old_val, value) !== 0)
  {
    switch (name)
    {
      case 'value':
        if (is_focused)
          break;
      default:
        target.setAttribute(name, value);
    }

    if (name.slice(0, 2) === 'on')
    {
      target[name] = new Function('event', value);
    }
  }
}

function set_attribute (target, name, value)
{
  if (is_boolean_attribute(name, value))
    set_boolean_attribute(target, name, value);
  else
    set_string_attribute(target, name, value);
}

function set_boolean_attribute (target, name, value)
{
  value = !value ? '' : value;

  target.setAttribute(name, "");

  if (target instanceof HTMLOptionElement &&
    target.parentElement instanceof HTMLSelectElement)
  {
    target.parentElement.selectedIndex = target.index;
  }
}

export function update_attributes (cur_node, new_node, node_callback)
{
  if (is_element(cur_node) && is_element(new_node) &&
    node_callback(new_node, NODE_UPDATE_ATTRIBUTES, cur_node))
  {

    const new_attrs = new_node.attributes
    const old_attrs = cur_node.attributes

    let attr_names = to_node_name_set(old_attrs)
    attr_names = to_node_name_set(new_attrs, attr_names);

    let updated = false
    attr_names.forEach(name => {

      const o = old_attrs.getNamedItem(name)
      const n = new_attrs.getNamedItem(name)

      const nv = attr_val(n)
      const ov = attr_val(o)

      if (name && update_attribute(cur_node, new_node, name, ov, nv, node_callback))
      {
        updated = true
      }
    })

    for (const property of Object.keys(INLINE_EVENT_PROPERTIES))
    {
      if (new_node[property])
      {
        cur_node[property] = new_node[property]
      }
    }

    node_callback(new_node, NODE_UPDATED_ATTRIBUTES, cur_node)

    return updated
  }
}

const strcmp = (a, b) => a < b ? -1 : +(a > b)

export function COMPARE (n, other)
{
  if (n.nodeType === other.nodeType)
  {
    switch (n.nodeType)
    {
      case Node.TEXT_NODE:
      case Node.CDATA_SECTION_NODE:
      case Node.COMMENT_NODE:
        return strcmp(n.nodeValue, other.nodeValue) === 0

      default:
        return strcmp(n.tagName, other.tagName) === 0 &&
          strcmp(n.id, other.id) === 0
    }
  }

  return false
}

function next (nodes, i, len, node_before)
{
  return i < len ? nodes[i] : (0 < i ? nodes[i - 1].nextSibling : node_before)
}

function transient (node, new_value = undefined)
{ // a transient node was already moved at the same position to the target
  // dom tree and serves only as a placeholder in the source tree
  if (is_bool(new_value))
  {
    node.transient = new_value
  }

  return is_bool(node.transient) ? node.transient : false
}

function append (parent, nodes, start, end, {node_before, node = node => node})
{
  const result = []
  while (start < end)
  {
    const n = nodes[start]

    const t = n.nodeType === Node.TEXT_NODE ? document.createTextNode('t') : document.createElement('t')
    transient(t, true)

    n.parentNode.insertBefore(t, n)

    parent.insertBefore(node(n, NODE_APPENDING), node_before)

    result.push(n)
    start++
  }

  return result
}

function remove (nodes, start, end, {node = node => node} = {})
{
  const result = []
  while (start < end)
  {
    const n = nodes[start++]

    //console.log(`remove node ${n}` )

    result.push(node(n, NODE_REMOVING).remove())
  }

  return result
}
