let object_id_counter = 0

export function object_id (o)
{
	if(o.__object_id === undefined) {
		Object.defineProperty(o, '__object_id', {
			value: object_id_counter++,
			enumerable: false,
			writable: false
		})
	}

	return o.__object_id
}