import {new_schema} from './schema.js';

export function db (name)
{
  const version_to_schema = {};
  
  return {
    version: (v) => (version_to_schema[v] = new_schema())
  }
}

/*function DB ()
{
  return {
    version: (v) => Schema(v)
  }
}*/