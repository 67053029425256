import {is_function} from "./util.js";
import {check_state} from "./precondition.js";

export function each (items, item_template)
{
	check_state(is_iterable(items))
	check_state(is_function(item_template))

	const result = []
	let i = 0
	for (const item of items) {
		result.push(item_template(item, i++))
	}

	return result
}

export function is_iterable (e)
{
	return e && is_function(e[Symbol.iterator])
}