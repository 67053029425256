import {event_types, new_parser} from './parser.js';
import {
	child_elements,
	create_element,
	first_child_element,
	is_attribute,
	is_document_fragment,
	is_element,
	is_node
} from "./element.js";
import {empty, is_array, is_function, is_number, is_object, is_string, is_tagged} from "../support/util.js";
import {on_component_created, on_component_creating} from "./hook.js";
import {array, top} from "../support/array.js";
import {case_styles, kebab_case, starts_with, upper_first} from "../text/string.js";
import {is_iterable} from "../support/iter.js";

const {freeze, entries} = Object

export const Node_type = freeze({
	ELEMENT: 1,
	ATTRIBUTE: 2,
	TEXT: 3,
	CDATA_SECTION: 4,
	ENTITY_REFERENCE: 5,
	ENTITY: 6,
	PROCESSING_INSTRUCTION: 7,
	COMMENT: 8,
	DOCUMENT: 9,
	DOCUMENT_TYPE: 10,
	DOCUMENT_FRAGMENT: 11,
	NOTATION: 12
})

function find_last_index (a, predicate)
{
	for (let i = a.length - 1; i >= 0; --i)
		if (predicate(i, a)) return i

	return -1
}

function set_attribute (n, k, v)
{
	if (is_document_fragment(n)) {
		n = n.firstElementChild
	}

	if (is_element(n)) {
		n.setAttribute(k, v)
	} else {
		throw 'invalid node'
	}
}

function create_properties (attrs = {}, exprs = [])
{
	const props = {}
	for (let [k, v] of entries(attrs)) {
		const match = v.match(/<!--\s*!([0-9]+)\s*-->/)
		if (match) v = exprs[match[1]]
		props[k] = v
	}

	return props
}

// Refactoring Required
export const args_stack = []

/** @Deprecated */
export function $ (k, def_val)
{
	const {args, dict} = top(args_stack)
	return args ? is_number(k) ? args[k] : dict[k] : def_val
}

let cur_component_stack = []

export function current_component_state ()
{
	return cur_component_stack[cur_component_stack.length - 1]
}

export function on_pre_rendering (component, props)
{
	cur_component_stack.push(component)

	on_component_creating(component, props)
}

export function on_post_rendering (node, props, i)
{
	const comp = cur_component_stack.pop()

	on_component_created(comp, node, props, i)
}

export function to_node (item, props = {})
{
	if (is_node(item)) {
		return item
	} else if (is_function(item)) {

		if (!empty(props)) on_pre_rendering(item, props)

		while (is_function(item)) {
			//console.log("call function " + item)

			let args = []
			if (top(args_stack)) {
				args = top(args_stack).args
			}

			if (!empty(props)) {
				props['args'] = args
				args = [props]
			}

			item = item(...args)
		}

		if (!empty(props)) on_post_rendering(item, props)

		return to_node(item);

	} else if (is_string(item) || is_number(item)) {
		return create_text_node(item)

	} else if (is_array(item)) {
		const parent = document.createDocumentFragment()
		item.forEach(i => parent.appendChild(to_node(i)))
		return parent;
	} else if (is_object(item) && is_node(item.node)) {
		return item.node;
	}
}

function create_text_node (s)
{
	return document.createTextNode(s)
}

export function node (arg1, ...rest)
{
	if (is_tagged(arg1, ...rest)) {
		return node_impl(arg1, {}, ...rest)
	}

	const args = [arg1, ...rest]
	const params = is_object(args[args.length - 1]) ? args[args.length - 1] : {}


	return (arg1, ...exprs) => node_impl(arg1, params, ...exprs)
}

function valid_dict_key (n, dict)
{
	const names = case_styles(n)

	//console.log("case styles for " + n)
	//console.log(names)

	for (n of names) {
		if (dict[n]) {
			return n
		}
	}
}

function filter_map (map, pred)
{
	const result = new Map
	for (let [k, v] of map) {
		if (pred(k, v)) {
			result.set(k, v)
		}
	}
	return result;
}

export const refs_map = new Map // TODO really static???

function node_impl (strings, {dict = {}, node = node => node} = {}, ...exprs)
{
	let start = 0
	const parser = new_parser()

	strings = is_array(strings) ? strings : [strings]

	//console.log("start parsing")
	//console.log(Object.keys(dict))

	const stack = []
	stack.push(document.createDocumentFragment())

	//const refs_map = new Map
	strings.reduce((res, cur, i) =>
	{

		const eof = i + 1 === strings.length
		const cur_str = `${res}${cur}${i < exprs.length ? `<!--!${i}-->` : ''}`

		let end = find_last_index([...cur_str], (i, a) => i > 0 && a[i] == '>' && a[i - 1] != '-') + 1

		if ((end > 0 && end > start) || eof) {
			end = end > 0 && end > start ? end : cur_str.length

			for (const event of parser.parse(cur_str, start, end, eof)) {
				let parent = top(stack)

				//console.log("parent node " + parent)
				//console.log("event")
				//console.log(event)

				let {name, text} = event, n

				//console.log(name)
				//console.log(text)
				//console.log(event.type)

				switch (event.type) {
				case event_types.OPEN:
					const {attrs} = event

					if (is_attribute(parent)) {
						do {
							const attr = stack.pop()
							parent = top(stack)
							if(!attrs[attr.name]) {
								attrs[attr.name] = attr.value
							}
						} while (is_attribute(parent))
					}

					//console.log("######## start_element " + name)

					const props = create_properties(attrs, exprs)
					const dict_key = valid_dict_key(name, dict)

					//console.log(dict_key + " vs. " + top(stack).__name)

					if (dict_key && dict_key != top(stack).__name &&
						dict[dict_key] !== top(cur_component_stack)) {
						const dom = document.createDocumentFragment()
						dom.__name = dict_key

						//console.log("create component " + dict_key)

						//console.log(dom.__name)

						stack.push(dom)
					} else {
						n = create_element(name, props)

						n = node(n)

						parent.appendChild(n)

						//console.log("append to parent")

						stack.push(n)
					}

					break

				case event_types.COMMENT_IN_TAG:

					if (starts_with(text, '!')) {
						let i = parseInt(text.substring(1))
						if (is_object(exprs[i])) {
							for (const k in exprs[i]) {
								const attr = document.createAttribute(k)
								attr.value = exprs[i][k]

								stack.push(attr)
							}
						}
					}

					break

				case event_types.COMMENT:

					if (starts_with(text, '!')) {
						let i = parseInt(text.substring(1))

						const it = is_iterable(exprs[i]) ? exprs[i] : [exprs[i]]
						for (const item of it) {
							let node = to_node(item)
							if (node != null) {
								parent.appendChild(node)
							}
						}
					}

					break

				case event_types.TEXT:
					const text_node = node(create_text_node(text))
					if (parent) parent.appendChild(text_node)
					break

				case event_types.CLOSE:
					let e = stack.pop()

					//console.log("######## end_element  " + name)

					if (is_document_fragment(e)) {
						const k = valid_dict_key(name, dict)
						let f = dict[k]

						//console.log("dictionary lookup " + f)

						if (is_function(f) && top(cur_component_stack) !== f) {

							const children = []

							for (const node of array(e.childNodes)) {
								const refs = refs_map.get(node) || [];
								children.push({
									node,
									refs
								});
							}

							const props = {
								children, attrs: create_properties(event.attrs, exprs)
							}

							//console.log("start render component for " + f + " " + n)

							n = to_node(f, props)

							if (is_node(n)) {
								if (is_element(n)) {
									if (!refs_map.has(n)) {
										refs_map.set(n, [f])
									} else {
										refs_map.get(n).push(f);
									}

									n.classList.add(upper_first(kebab_case(k)))
								}

								parent = stack[stack.length - 1]

								n = node(n, f)

								parent.appendChild(n)
							}
						}

						//mode = Render_mode.PATCH
					}

					break
				}
			}

			start = end
		}

		return cur_str
	}, '')

	const dom = stack.pop(), children = child_elements(dom)

	//console.log("node dom " + dom + " child count " + array(dom.children).length)

	if (children.length > 1) {
		const root = document.createElement('div')
		root.appendChild(dom)
		dom.appendChild(root)
	}

	//console.log("node returning " + dom.firstElementChild)

	return first_child_element(dom)
}