const emptyArray = [],
  emptyObject = {},
  doc = document,
  emptyElement = doc.createElement("div");

const toString = emptyObject.toString,
  arrayForEach = emptyArray.forEach,
  arrayMap = emptyArray.map,
  arrayFilter = emptyArray.filter,
  arraySlice = emptyArray.slice,
  arraySplice = emptyArray.splice,
  arrayIndexOf = emptyArray.indexOf,
  arrayLastIndexOf = emptyArray.lastIndexOf;

function merge (srcList, mergeList) {
  arrayForEach.call(mergeList, function (mergeElement) {
    if (arrayIndexOf.call(srcList, mergeElement) < 0) {
      srcList[srcList.length] = mergeElement;
    }
  });
}

function array_like (value) {
  return (typeof value.length == "number");
}

function Fluent (selector)
{
  return new Fluent.fn.init(selector)
}

Fluent.fn = Fluent.prototype = {
  constructor: Fluent,
  length: 0,
  init: function (s, base = document) {

    let elems = []

    if(typeof s === 'string') {
      elems =  [...base.querySelectorAll(s)];
    }

    if (typeof s === "object") {
      elems = [s];
    }

    if(array_like(s)) {
      elems = arrayFilter.call(s, item => {
        return !!item.nodeType;
      });
    }

    let len = this.length = elems.length
    while(len--) {
      this[len] = elems[len]
    }

    this['class'] = {

      add: s => {
        if (!s){
          return this;
        }

        return this.each(e => {
          e.classList.add(s);
        });
      },

      remove: s =>
      {
        if (!s){
          return this;
        }

        return this.each(e => {
          e.classList.remove(s);
        });
      }
    };

    return this;
  },

  query: function (s)
  {
    let array = [], r;
    for (var i = 0, len = this.length;i < len;i++) {
      r = [...this[i].querySelectorAll(s)]
      array = array.concat(r)
    }

    return new Fluent(array);
  },

  each: arrayForEach,
  map: arrayMap,
  filter: arrayFilter,
  indexOf: arrayIndexOf,
  lastIndexOf: arrayLastIndexOf
};

Fluent.fn.init.prototype = Fluent.fn;

export function emplace_api (slot = 'ui')
{
  if (!(slot in Element.prototype))
  {
    Object.defineProperty(Element.prototype, 'ui', {
      get: function () {
        return new Fluent(this);
      }
    });
  }
}