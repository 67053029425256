import {emplace_flow_api} from "../dom/flow.js"
import {ltrim, starts_with} from "../text/string.js"
import {path_stack, relative_path} from "../text/path.js";

const CATCH_ALL_REGEXP = /\.*/g
const find_route_for = (path, router) =>
{

  const routes = find_routes_for(path, router)
  for (const route of routes)
  {
    return route
  }

  return false
}

const is_event_available = (event_name) => typeof window !== 'undefined' && event_name in window
const href_attribute = (link) => link.getAttribute('href')
const add_listener = (event_name, callback) => window.addEventListener(event_name, callback)

const cur_location_text = () => window.location.href

let next_prev_location = new URL(window.location.href)
let prev_location = null

let listening_interval

function cur_address_url (router)
{
  const cur_url = new URL(cur_location_text())
  if (starts_with(cur_url.hash, router.fragment_identifier))
  {
    return new URL(ltrim(cur_url.hash, router.fragment_identifier), cur_location_text())
  }

  return cur_url
}

let location_change_running = false

async function on_location_change (event, router)
{
  try
  {
    if (location_change_running) return

    location_change_running = true

    prev_location = next_prev_location

    const location_url = new URL(cur_location_text())
    let path = location_url.pathname

    if (router.use_fragment && !starts_with(location_url.hash, router.fragment_identifier))
    {
      return false
    }

    path = ltrim(location_url.hash, router.fragment_identifier)

    const match = find_route_for(path, router)

    if (match)
    {
      const url = cur_address_url(router);

      const result = match.route.callback(path)
      if (result)
      {
        if (starts_with(url.hash, '#'))
        {
          if (is_event_available('onpopstate'))
          {
            const title = result.title || url.pathname
            const state = result.state || {}
            history.replaceState(state, title, prev_location.href)
          }

          window.location.hash = url.hash

        }
      }
    }
  } finally
  {
    location_change_running = false
    next_prev_location = new URL(window.location.href)
  }
}

function find_routes_for (path, router)
{
  return router.routes.map(route =>
  {
    const regexp = route.route instanceof RegExp ? route.route : null
    if (regexp != null)
    {
      const match = path.replace(/^\/+/, '/').match(regexp)
      return match ? {match, route} : false
    }
  }).filter(m => m)
}

function listen_on_link (link, router)
{
  if (!link.router_listener_attached)
  {
    link.addEventListener('click', event =>
    {
      const href = href_attribute(link).replace(/\/+$/, '').replace(/^\/+/, '/')

      if (!starts_with(href, 'ionic:'))
      {
        event.preventDefault() // TODO REMOVE ME
        if (navigate(event, href, router))
        {
          event.preventDefault()
        }
      }
    })

    link.router_listener_attached = true
  }
}

function trim_fragment_identifier (url, router)
{
  if (starts_with(url.hash, router.fragment_identifier))
  {
    url = new URL(ltrim(url.hash, router.fragment_identifier), cur_location_text())
  }

  return url
}

function navigate (event, path, router)
{
  if (!path)
  {
    if (window.location.href !== cur_address_url(router).href)
    {
      path = relative_path(path_stack(window.location.pathname).slice(0, -1), cur_address_url(router).pathname)
    }
  }

  const is_absolute = !!path.match(RegExp('^(?:[a-z]+:)?//'))
  let cur_url = trim_fragment_identifier(new URL(cur_location_text()), router)

  if (is_absolute)
  {
    const to_url = new URL(path)
    if (!router.follow_absolute && cur_url.host != to_url.host)
    {
      console.log("do not follow absolute url")
      return false
    }


    path = to_url.href
  }

  let new_url = new URL(ltrim(path, router.fragment_identifier), cur_url.toString())

  const has_fragment = new_url.hash.length > 0
  const is_same_path = new_url.pathname.length == 0 || new_url.pathname == cur_url.pathname
  const is_same_query = new_url.search == cur_url.search

  if (router.use_fragment && !(is_same_path && is_same_query && has_fragment))
  {
    cur_url = new URL(cur_location_text())
    cur_url.hash = path
    cur_url.hash = router.fragment_identifier + path

    if (!starts_with(path, '#'))
    {
      cur_url.hash = router.fragment_identifier + path
    }
    new_url = cur_url
  }
  else
  {
    new_url = new URL(path, cur_location_text())
  }

  if (!router.use_fragment)
  {
    history.pushState({}, '', new_url.toString())
    on_location_change(event, router)
  }
  else if (typeof window !== 'undefined' && window.location.hash != new_url.hash)
  {
    window.location.hash = new_url.hash
  }
  else
  {
    on_location_change(null, router)
  }
}

function emplace_link_listeners (router, selector)
{
  window.arrive(selector, (elem) =>
  {
    listen_on_link(elem, router)
  })
}

function emplace_listener (router)
{
  if (!router.use_fragment && is_event_available('onpopstate'))
  {
    add_listener('popstate', (e) => on_location_change(e, router))
  }
  else if (router.use_fragment && is_event_available('onhashchange'))
  {
    add_listener('hashchange', (e) => on_location_change(e, router))
  }
  else if (router.use_fragment)
  {
    let current, cached = cur_location_text()
    const check = () =>
    {
      current = cur_location_text()
      if (cached !== current)
      {
        cached = current
        on_location_change(null, router)
      }

      listening_interval = setTimeout(check, 200)
    }

    check()
  }
  else
  {
    throw new Error("browser doesn't support History API");
  }
}

function add_route (route, callback, router)
{
  if (route == '*')
  {
    route = CATCH_ALL_REGEXP
  }
  else
  {
    route = RegExp('^' + route)
  }

  router.routes.push({
    route, callback
  })
}

export function new_router ({follow_absolute = false, use_fragment = true, fragment_identifier = '#!', link_selector = 'a[href]:not(.referrer)'} = {})
{
  const routes = [], router = {routes, follow_absolute, use_fragment, fragment_identifier}

  emplace_flow_api()

  emplace_listener(router)
  emplace_link_listeners(router, link_selector)

  window.prev_location = new URL(window.location.href)

  return {
    prev_location: () => prev_location,
    navigate: (path) => navigate(null, path, router),
    add: (route, callback) => add_route(route, callback, router)
  }
}