import {Node_type} from './node.js'
import {equals_ignoring_case, starts_with} from "../text/string.js";
import {empty, is_object} from '../support/util.js'
import {object_id} from '../support/object.js'

const Tag_name = Object.freeze({
	A: 'A',
	META: 'META',
	INPUT: 'INPUT',
	LABEL: 'LABEL',
	SELECT: 'SELECT',
	TEXTAREA: 'TEXTAREA',
	FORM: 'FORM'
})

export const is_element = e => is_object(e) && (e).nodeType === Node_type.ELEMENT
export const is_node = e => is_object(e) && (e).nodeType

export function selection (el, pos)
{
	if (el && el.value && el.value.length >= pos &&
	el === active_element(el.ownerDocument))
	{
		if (el.createTextRange)
		{
			const range = el.createTextRange()
			range.move('character', pos)
			range.select()
		}
		else {
			try {
				el.setSelectionRange(pos, pos)
			} catch (e)
			{
				console.warn('the element does not support selection')
			}
		}
	}
}

export function active_element (parent)
{
	parent = parent || document
	const active = parent.activeElement
	if (active && active.shadowRoot)
	{
		return active_element(active.shadowRoot)
	}

	return active
}

export function create_element (name, props = {})
{
	const d = document;
	let xmlns = props['xmlns'];
	if (empty(xmlns) && (/svg/i).test(name))
	{
		xmlns = 'http://www.w3.org/2000/svg';
	}

	const e = xmlns ? d.createElementNS(xmlns, name) : d.createElement(name);

	return set_properties(e, props)
}

export function walk_nodes (nodes = [], callback)
{
	return nodes.forEach(n => walk(n, callback))
}

export function walk (n, callback)
{
	const root = n.parentNode || n
	while (n)
	{
		if (false === callback(n)) return false;

		if (n.hasChildNodes())
		{
			n = n.firstChild
		}
		else {
			while (n.nextSibling == null && n !== root)
			{
				n = n.parentNode
			}

			if (n === root) break
			n = n.nextSibling
		}
	}

	return true;
}

export const INLINE_EVENT_PROPERTIES = Object.freeze({
	'onabort': 1,
	'onanimationcancel': 1,
	'onanimationend': 1,
	'onanimationiteration': 1,
	'onauxclick': 1,
	'onblur': 1,
	'oncancel': 1,
	'oncanplay': 1,
	'oncanplaythrough': 1,
	'onchange': 1,
	'onclick': 1,
	'onclose': 1,
	'oncontextmenu': 1,
	'oncopy': 1,
	'oncuechange': 1,
	'oncut': 1,
	'ondblclick': 1,
	'ondurationchange': 1,
	'onended': 1,
	'onerror': 1,
	'onfocus': 1,
	'onformdata': 1,
	'ongotpointercapture': 1,
	'oninput': 1,
	'oninvalid': 1,
	'onkeydown': 1,
	'onkeypress': 1,
	'onkeyup': 1,
	'onload': 1,
	'onloadeddata': 1,
	'onloadedmetadata': 1,
	'onloadend': 1,
	'onloadstart': 1,
	'onlostpointercapture': 1,
	'onmousedown': 1,
	'onmouseenter': 1,
	'onmouseleave': 1,
	'onmousemove': 1,
	'onmouseout': 1,
	'onmouseover': 1,
	'onmouseup': 1,
	'onpaste': 1,
	'onpause': 1,
	'onplay': 1,
	'onplaying': 1,
	'onpointercancel': 1,
	'onpointerdown': 1,
	'onpointerenter': 1,
	'onpointerleave': 1,
	'onpointermove': 1,
	'onpointerout': 1,
	'onpointerover': 1,
	'onpointerup': 1,
	'onreset': 1,
	'onresize': 1,
	'onscroll': 1,
	'onselect': 1,
	'onselectionchange': 1,
	'onselectstart': 1,
	'onsubmit': 1,
	'ontouchcancel': 1,
	'ontouchstart': 1,
	'ontransitioncancel': 1,
	'ontransitionend': 1,
	'onwheel': 1
})

export const DIRECT_ATTR_MAP = Object.freeze({
	'cellpadding': 'cellPadding',
	'cellspacing': 'cellSpacing',
	'colspan': 'colSpan',
	'frameborder': 'frameBorder',
	'height': 'height',
	'maxlength': 'maxLength',
	'nonce': 'nonce',
	'role': 'role',
	'rowspan': 'rowSpan',
	'type': 'type',
	'usemap': 'useMap',
	'contenteditable': 'contenteditable',
	'valign': 'vAlign',
	'width': 'width'
})

export function get_property (e, name)
{
	if (e)
	{
		let r = null

		try {
			switch (name)
			{
			case 'style':
				return (r = e.style.cssText)
			case 'class':
				return (r = e.className)
			case 'for':
				return (r = e.htmlFor)
			default:
				if (DIRECT_ATTR_MAP.hasOwnProperty(name))
				{
					return (r = e.getAttribute(DIRECT_ATTR_MAP[name]))
				}
			}

			return (e[name] || e.getAttribute(name))

			//if (name.slice(0, 2) === 'on') {
			//}
		} finally {
			//console.log(`get_property ${name} ${r}`);
		}
	}
}

export function reflow (e)
{
	e = e || document.documentElement;
	void (e.offsetHeight);
}

export function set_properties (e, props = {})
{
	for (const [k, v] of Object.entries(props))
	{
		switch (k)
		{
		case 'style':
			e.style.cssText = v
			break
		case 'class':
			e.className = v
			break
		case 'for':
			e.htmlFor = v
			break
		default:
			if (DIRECT_ATTR_MAP.hasOwnProperty(k))
			{
				e.setAttribute(DIRECT_ATTR_MAP[k], v)
			}
			else /*if (!starts_with(k, 'on'))*/
			/*else if (!starts_with(k, 'aria-', 'data-'))*/
			{
				let val = v
				if (starts_with(k, 'on'))
				{
					val = object_id(val)
				}

				e.setAttribute(k, val)
			}

			e[k] = v
		}
	}

	return e;
}

export function html_text (e)
{
	return e.outerHTML
}

export function position (e)
{
	let i = 0
	const children = child_elements(e.parentNode)
	while (children[i] != e)
	{
		i++;
	}

	return i;
}

export function first_child_element (e)
{
	if (!e.firstElementChild && e.childNodes)
	{
		let i = 0, node, nodes = e.childNodes
		while (node = nodes[i++])
		{
			if (node.nodeType === 1)
			{
				return node
			}
		}
	}

	return e.firstElementChild
}

export function child_elements (e)
{
	if (!e.children && e.childNodes)
	{

		let i = 0, node, nodes = e.childNodes, children = []
		while (node = nodes[i++])
		{
			if (node.nodeType === 1)
			{
				children.push(node)
			}
		}

		return children
	}

	return e.children
}

export function is_child_of (e, p)
{
	return !!parent(e, n => n.isSameNode(p))
}

export function is_html_button (e)
{
	return e instanceof HTMLButtonElement
}

export function is_html_element (e)
{
	return is_element(e) && e instanceof HTMLElement
}

export function is_svg_element (e)
{
	return is_element(e) && e instanceof SVGElement;
}

export function is_document_fragment (e)
{
	return e instanceof DocumentFragment
}

export function is_attribute (n)
{
	return n instanceof Attr
}

const has_tag_name = (e, name) => is_element(e) && equals_ignoring_case(e.tagName, name)

const matches = HTMLElement.prototype.matches || HTMLElement.prototype.webkitMatchesSelector ||
HTMLElement.prototype.mozMatchesSelector || HTMLElement.prototype.msMatchesSelector
export const matches_selector = (e, selector) => is_html_element(e) && matches.call(e, selector)

export function is_html_anchor_element (e)
{
	return is_html_element(e) && has_tag_name(e, Tag_name.A)
}

export function is_html_meta_element (e)
{
	return is_html_meta_element(e) && has_tag_name(e, Tag_name.META)
}

export function is_html_input_element (e)
{
	return is_html_element(e) && has_tag_name(e, Tag_name.INPUT)
}

export function is_html_select_element (e)
{
	return is_html_element(e) && has_tag_name(e, Tag_name.SELECT)
}

export function is_html_textarea_element (e)
{
	return is_html_element(e) && has_tag_name(e, Tag_name.TEXTAREA)
}

export function is_html_anchor (e)
{
	return e instanceof HTMLAnchorElement
}

export function is_html_label_element (e)
{
	return is_html_element(e) && has_tag_name(e, Tag_name.LABEL)
}

export function is_html_form_element (e)
{
	return is_html_element(e) &&
	has_tag_name(e, Tag_name.FORM) ||
	has_tag_name(e, Tag_name.INPUT) ||
	has_tag_name(e, Tag_name.TEXTAREA) ||
	has_tag_name(e, Tag_name.SELECT);
}

export function parent_by_tag_name (node, name)
{
	if (node instanceof Node)
	{
		return parent(node, n => equals_ignoring_case(n.tagName, name));
	}
}

export function parse_from_text_html (text, opt_parser)
{
	return parse_from_text(text, 'text/html', opt_parser)
}

export function parse_from_text (text, type, opt_parser)
{
	opt_parser = opt_parser || new DOMParser()
	return opt_parser.parseFromString(text, type)
}

export function parent (node, callback)
{
	if (!node || !callback) return;

	let parent = node.parentNode;
	while (parent && parent.tagName !== "HTML")
	{
		if (callback(parent)) return parent;
		parent = parent.parentNode;
	}

	return null;
}