import {is_string} from "./util.js";

const def = {
  'state': "state doesn't satisfy expression",
  "string": "variable is not a string"
}

export const check_string = (v, m, args) => is_string(v) ? v : error(def.state, m, args, TypeError);
export const check_state = (expr, m, args) => !expr ? error(def.state, m, args, TypeError) : expr;

function error (default_msg, msg, args, exception)
{
  if (!msg) throw new exception(default_msg);
  if (!args) throw new exception(check_string(msg, 'provided message must be string'));
}
