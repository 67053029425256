import {global, provide} from "../support/util.js";
import {merge} from "../dom/merge.js";

export {$} from '../dom/render.js'
export {node} from '../dom/node.js'
export {merge} from '../dom/merge.js'
export {render} from './render.js'
export {render2} from '../dom/render.js'
export {View} from './view.js'
export {Page} from './page.js'
export {Tabs, Tab} from './tabs.js'
export {Tools, Button, BackButton, SubmitButton} from './tools.js';
export {new_router} from '../routing/router.js'
export {state, on_update} from '../dom/hook.js'

global().ui = global().ui || {}

provide('ui.dom.merge', merge)