import {render2 as dom_render} from '../dom/render.js'
import {Page} from "./page.js";
import {View} from "./view.js";
// import {Format} from "./format.js";
import {Tab, Tabs} from "./tabs.js";
import {BackButton, SubmitButton, Tools} from "./tools.js";
import {is_object} from "../support/util.js";

const markup = {
	'page': Page,
	'view': View,
	'tabs': Tabs,
	'tab': Tab,
	'Tools': Tools,
	'back-button': BackButton,
	'submit-button': SubmitButton
}

export function render (arg1, ...rest)
{
	return (s_arg1, ...s_rest) =>
	{
		let args = []
		let dict = {}
		if (s_arg1) {
			args = [s_arg1, ...s_rest]
			dict = is_object(args[args.length - 1]) ? args[args.length - 1] : {}
		}

		dict = Object.assign(dict, markup)

		return dom_render(arg1, ...rest)(dict);
	}
}