import {arrive} from "../../webui/js/dom/flow.js"
import {Glider} from "./glider.js";
import {intercept_forms, submit_form_data, xhr_response_text} from '../../webui/js/dom/form.js';
import {
	is_child_of,
	is_html_anchor,
	is_html_button,
	parent_by_tag_name,
	parse_from_text_html
} from '../../webui/js/dom/element.js';
import {merge, NODE_ENTERING, NODE_VISITING} from "../../webui/js/dom/merge.js";
import {empty, not_empty, parse_url, strcmp} from "../../webui/js/support/util.js";

import {html_text, render} from "../../webui-pro/js/ui/index.js"
import {install_modal, modal_merge_callback} from "../../webui/js/ui/modal.js"
import {markerer_clusterer} from "./marker-clusterer.js";

const MarkerClusterer = markerer_clusterer();

window.arrive('ui-input', target_node => {
	render(html_text(target_node))({target_node})
})

install_modal('sx');


function new_xml_http_request (method, url, async)
{

	const xhr = new XMLHttpRequest
	xhr.open(method, url, async)

	xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest')

	xhr.addEventListener("load", () => {
		end_progress()
	})

	xhr.addEventListener("loadstart", () => {
		start_progress()
	})

	xhr.addEventListener("progress", event => {
		if (event.lengthComputable) {
			const complete = (event.loaded / event.total * 100 | 0)
			log(complete + '%')
		}
		else {
			log("on progress, not computable")
		}
	})

	return xhr
}

function start_progress ()
{
	const progress_elem = document.getElementById('progress')
	progress_elem.removeAttribute('hidden');
}

function end_progress ()
{
	const progress_elem = document.getElementById('progress')
	progress_elem.setAttribute('hidden', '');
}

function load_page (url, callback)
{
	const xhr = new_xml_http_request('GET', url, true)

	xhr.onload = function () {

		const resp_location = xhr.getResponseHeader('X-Location')

		merge_xhr_result(xhr)
		if (callback) {
			callback(resp_location)
		}
	}

	xhr.onerror = function () {
		alert("error on click edit link")
	}

	xhr.send();
}

(function intercept_edit_links () {

	function on_click_edit_link (e)
	{
		e.preventDefault()

		let elem = e.target

		if (!is_html_anchor(elem)) {
			elem = parent_by_tag_name(elem, 'a')
		}

		if (is_html_anchor(elem)) {
			start_progress()

			const href = elem.getAttribute('href')

			log("start loading href " + href)

			const url = parse_url(href)

			load_page(url, (resp_url) => {
				on_merge_result(url, resp_url.toString())
			})
		}
	}

	const handler = elem => {
		const href = elem.getAttribute('href')
		if (!href.startsWith('#')) {
			elem.addEventListener('click', on_click_edit_link);
		}
	}

	arrive('a.ajax.link', handler)


	arrive('a.edit.link', handler)
	arrive('a.delete.link', handler)
	arrive('a.delete.button', handler)

})()

let last_clicked_element

window.document.addEventListener('click', (e) => {

	if (is_html_button(e.target)) {
		last_clicked_element = e.target
	}
	else {
		const button = parent_by_tag_name(e.target, 'button');
		if (button) {
			last_clicked_element = button;
		}
	}

	log('capturing last clicked element ' + last_clicked_element);

}, {passive: true, capture: true});

function merge_xhr_result (xhr)
{
	if (xhr.status >= 200 && xhr.status < 400) {
		// Success!
		const response = xhr_response_text(xhr)

		const new_document = parse_from_text_html(response)

		const cur_node = document.body
		const new_node = new_document.body

		merge({
			cur_node,
			new_node,
			node: (node, type, cur_node, name, old_val, new_val) => {
				switch (type) {
				//case NODE_UPDATE_ATTRIBUTES:
				//return !(cur_node === document.body || cur_node.matches('.sx-modal-wrapper'));
				/*case NODE_ENTERING: // entering node

					if (cur_node instanceof HTMLElement) {
						const id = cur_node.getAttribute('id')
						if ("map".localeCompare(id) === 0) {
							return false
						}
					}

					break

				case NODE_VISITING: // visiting two nodes
					if (node instanceof HTMLElement) {
						const id = node.getAttribute('id')
						if ("map".localeCompare(id) === 0) {
							const cur_map = document.getElementById('map');
							if (!cur_map.isSameNode(cur_node)) {
								node.parentNode.replaceChild(cur_map, node)
								return node
							}
						}

						break;
					}*/
				}


				return modal_merge_callback(node, type, cur_node, name, old_val, new_val);

			}
		});

		//update_attributes(document.documentElement, new_document.documentElement, () => true)

		return new_document;

	}
	else {
		// We reached our target server, but it returned an error
		alert("We reached our target server, but it returned an error")
	}

	return false;
}


function on_merge_result (req_url, resp_url)
{
	log("on_merge_result");
	const origin_url = parse_url(window.location.href)
	resp_url = parse_url(resp_url)
	req_url = parse_url(req_url)

	const hash = [resp_url.hash, req_url.hash, origin_url.hash].find(not_empty)
	let target_id = hash ? hash.substr(1) : null
	if ((0 === strcmp(req_url.pathname, resp_url.pathname) ||
	0 === strcmp(origin_url.pathname, resp_url.pathname)) && hash)
	{
		resp_url.hash = hash
	}

	const url = resp_url.toString()
	const state = {
		url, title: ''
	}

	log("pushstate, targeting " + is_targeting + ",document location: " + document.location + ", page info: " + JSON.stringify(state));

	history.pushState(state, state.title, state.url)

	log(state);

	if (target_id) {
		set_target(target_id)
	}


	window['init_map']();
}

window.onpopstate = function (event)
{
	log(event);

	if (!event.state && window.location.hash) {

		const hash = window.location.hash
		const page_info = {
			url: window.location.toString(),
			title: document.title
		}

		history.replaceState(page_info, "", page_info.url)
		if (!empty(hash)) {
			set_target(hash.substr(1))
		}
	}

	if (event.state) {
		if (event.state.url) {
			const url = event.state.url;
			load_page(url);
		}

		/*log("popstate, targeting " + is_targeting + ",document location: " + document.location + ", event: " + JSON.stringify(event));

		 let url = document.location
		 if (event && event.state && event.state.url) {
		 url = event.state.url
		 }*/

	}


	//assign_page(url)
}

let is_targeting = false

function set_target (id)
{
	is_targeting = true

	id = id || window.location.hash.substr(1)
	if (id) {

		log('set target ' + id);

		// remove andy target classes
		[...document.querySelectorAll('[id].target')].forEach(el => {
			el.classList.remove('target')
		})

		let el = document.querySelector('[id]:target')
		if (el) {
			el.setAttribute('hidden', 'hidden');
		}

		el = document.getElementById(id)
		if (el) {
			el.removeAttribute('hidden')
			el.classList.add('target')
		}
	}

	is_targeting = false
}

function log (msg)
{
	//log(msg);
}

intercept_forms((e, form) => {

	start_progress()

	const target = form || e.target;

	let action_url = target.action || window.location.href

	log('form action url ' + action_url);
	log('last clicked element ' + last_clicked_element);

	const data = new FormData(target)
	if (is_child_of(last_clicked_element, target)) {
		action_url = last_clicked_element.getAttribute('formaction') || action_url
		const name = last_clicked_element.name;
		const val = last_clicked_element.value;
		if (!empty(name)) {
			data.append(name, val)
		}
	}

	if (e && e.preventDefault) {
		e.preventDefault();
	}


	if (submit_origin && submit_origin.value) {
		if (submit_origin.value.length < 3) {
			data.append(submit_origin.name, '');
		}
	}

	submit_form_data(data, action_url, [], (xhr) => {

		last_clicked_element = null

		//const fragment = xhr.getResponseHeader('X-Fragment')
		const resp_url = xhr.getResponseHeader('X-Location')

		submit_origin = undefined

		//set_target(fragment)

		const new_document = merge_xhr_result(xhr)
		if (new_document) {

			const old_map_script = document.body.querySelector('script[data-name=maps]');
			const new_map_script = new_document.body.querySelector('script[data-name=maps]');
			if (old_map_script && new_map_script) {
				const parent = old_map_script.parentElement;
				parent.removeChild(old_map_script);
				parent.appendChild(new_map_script);
			}

			on_merge_result(action_url, resp_url)
		}

		end_progress()
	});
});

let submit_origin;
window.submit_form_oninput = function (ctx)
{
	let val = ''
	if (ctx && ctx.form) {
		submit_origin = ctx;
		ctx.form.submit()
	}
}

function init_glider ()
{

	function responsive_options (num)
	{
		const options = [];

		options.push({
			breakpoint: 600, /* tablet */
			settings: {
				slidesToShow: 3.1,
			}
		});

		options.push({
			breakpoint: 960, /* screen */
			settings: {
				slidesToShow: 4,
			}
		});

		if (num > 4) {
			options.push({
				breakpoint: 1200, /* large screen */
				settings: {
					slidesToShow: 5.1,
				}
			});
		}

		return options
	}

	arrive('.glider', function (glider) {

		const prev = glider.parentNode.querySelector('.glider-prev');
		const next = glider.parentNode.querySelector('.glider-next');
		const num = glider.querySelector('.sport-tiles') ? 5 : 4;

		new Glider(glider, {
			skipTrack: true,
			slidesToShow: 2.1,
			responsive: responsive_options(4),
			arrows: {
				prev: prev,
				next: next
			}
		});
	});
}

function init_geolocation ()
{
	window['use_current_location'] = use_current_location;
}

function create_map (pos, zoom, map_node)
{
	zoom = zoom || 15;

	let x = [
		{
			"stylers": [
				{
					"hue": "#FF1A00"
				},
				{
					"invert_lightness": true
				},
				{
					"saturation": -100
				},
				{
					"lightness": 33
				},
				{
					"gamma": 0.5
				}
			]
		},
		{
			"featureType": "water",
			"elementType": "geometry",
			"stylers": [
				{
					"color": "#2D333C"
				}
			]
		}
	];

	let backgroundColor = "black";
	if (document.body.classList.contains('light')) {
		x = [];
		backgroundColor = 'white';
	}

	map_node = map_node || document.getElementById("map");

	return new google.maps.Map(map_node, {
		backgroundColor,
		center: pos,
		mapTypeId: google.maps.MapTypeId.ROADMAP,
		zoomControl: true,
		mapTypeControl: false,
		scaleControl: true,
		streetViewControl: false,
		rotateControl: false,
		fullscreenControl: false,
		zoom: zoom,
		styles: x
	});
}

function init_maps ()
{
	let map;
	window['geojson'] = function (results)
	{
		if (map) {
			const markers = results.features.map((feature, i) =>
			{
				const coords = results.features[i].geometry.coordinates;
				const props = results.features[i].properties;
				const latLng = new google.maps.LatLng(coords[0], coords[1]);
				const name = props['name'];
				const address_line = props['address_line'];
				const id = props['id'];
				const slug = props['slug'];

				const marker = new google.maps.Marker({
					position: latLng,
					map,
					title: name,
					icon: {
						anchor: new google.maps.Point(16, 16),
						url: 'data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 45 50\'%3E%3ClinearGradient id=\'a\' gradientUnits=\'userSpaceOnUse\' x1=\'33\' y1=\'25\' x2=\'12\' y2=\'25\'%3E%3Cstop offset=\'0\' stop-color=\'%23c6761e\'/%3E%3Cstop offset=\'.128\' stop-color=\'%23c6761e\'/%3E%3Cstop offset=\'.216\' stop-color=\'%23cb7b1c\'/%3E%3Cstop offset=\'.301\' stop-color=\'%23d98918\'/%3E%3Cstop offset=\'.56\' stop-color=\'%23f1b53f\'/%3E%3Cstop offset=\'1\' stop-color=\'%23f1b53f\'/%3E%3C/linearGradient%3E%3Cpath d=\'M22.5 11.5c-5.8 0-10.5 4.4-10.5 9.9 0 5 9 15.4 10.5 17.1C24 36.8 33 26.4 33 21.4c0-5.5-4.7-9.9-10.5-9.9z\' fill=\'url(%23a)\'/%3E%3Cpath d=\'M27.4 18.4h-2.2l-2.6 2.3c-.1.1-.2.1-.2 0l-2.6-2.3h-2.2l3.6 3.4c.1.1.1.1 0 .2l-3.6 3.4h2.2l2.6-2.3c.1-.1.2-.1.2 0l2.6 2.3h2.2L23.8 22c-.1-.1-.1-.1 0-.2l3.6-3.4z\' fill=\'%23fff\'/%3E%3C/svg%3E',
						scaledSize: new google.maps.Size(48, 48)
					}
				});

				marker.addListener('click', function () {

					const content = `
						<div>
							<a href="/${id}/${slug}" class="sport-map-info-link">
								<h2 class="sx-h5 sx-truncate">${name}</h2>
								<p class="sx-truncate">${address_line}</p>
							</a>
						</div>
					`

					const info_win = new google.maps.InfoWindow({
						content,
						minWidth: 352,
						maxWidth: 480,
					});

					info_win.open(map, marker);
				});

				return marker;
			});

			const styles = [];
			for (let i = 0; i < 5; ++i) {
				styles.push({
					url: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 45 50'%3E%3ClinearGradient id='a' gradientUnits='userSpaceOnUse' x1='33' y1='25' x2='12' y2='25'%3E%3Cstop offset='0' stop-color='%23c6761e'/%3E%3Cstop offset='.128' stop-color='%23c6761e'/%3E%3Cstop offset='.216' stop-color='%23cb7b1c'/%3E%3Cstop offset='.301' stop-color='%23d98918'/%3E%3Cstop offset='.56' stop-color='%23f1b53f'/%3E%3Cstop offset='1' stop-color='%23f1b53f'/%3E%3C/linearGradient%3E%3Cpath d='M22.5 11.5c-5.8 0-10.5 4.4-10.5 9.9 0 5 9 15.4 10.5 17.1C24 36.8 33 26.4 33 21.4c0-5.5-4.7-9.9-10.5-9.9z' fill='url(%23a)'/%3E%3C/svg%3E",
					height: 58,
					width: 58,
					fontWeight: '600',
					fontStyle: 'normal',
					fontFamily: 'Montserrat',
					anchorText: [18, -0],
					textSize: 14,
					textColor: 'white',
				});
			}

			new MarkerClusterer(map, markers, {
				styles
			});
		}
	}

	window['create_map'] = create_map;

	window['init_map'] = function ()
	{
		const map_node = document.getElementById('map');
		if (map_node) {

			const lat = Number(map_node.dataset.lat);
			const lng = Number(map_node.dataset.lng);
			const mode = map_node.dataset.mode || 'default';

			const zoom = map_node.dataset.zoom || 11;
			const title = map_node.dataset.title || '';
			const src = map_node.dataset.src;
			const pos = {
				lat, lng
			};

			map = create_map(pos, parseInt(zoom), map_node);

			if('default' === mode) {
				const script = document.createElement("script");
				script.src = src;
				document.getElementsByTagName("head")[0].appendChild(script);
			} else if ('marker' === mode) {
				new google.maps.Marker({
					position: pos,
					map,
					title
				});
			}
		}
	}
}

function use_current_location (form, opt_pairs = {})
{
	let watch_id;

	function new_hidden_input (name, val)
	{
		const input = document.createElement('input');
		input.setAttribute('name', name);
		input.setAttribute('value', val);
		input.setAttribute('type', 'hidden');
		return input;
	}

	function geo_success (pos)
	{
		var lat = pos.coords.latitude;
		var lng = pos.coords.longitude;

		navigator.geolocation.clearWatch(watch_id);

		form.appendChild(new_hidden_input('lat', lat));
		form.appendChild(new_hidden_input('lng', lng));

		for (let k in opt_pairs) {
			const input = new_hidden_input(k, opt_pairs[k]);
			form.appendChild(input);
		}

		form.submit();
	}

	function geo_error ()
	{
		log('geolocation error');
	}

	const geo_options =
	{
		enableHighAccuracy: true,
		maximumAge: 30000,
		timeout: 27000
	};

	watch_id = navigator.geolocation.watchPosition(geo_success, geo_error, geo_options);
}

(function () {

	init_glider();
	init_maps();
	init_geolocation();

	window['submit_form'] = function (control)
	{
		const id = control.getAttribute("id");
		if(id) {
			const spinner = document.querySelector("#" + id + " ~ .sx-icon-spinner");
			if(spinner) {
				spinner.removeAttribute('hidden');
				control.setAttribute('hidden', '');
			}
		}

		control.form.submit();
	}

	const state = {
		title: document.title,
		url: window.location.href
	}

	history.replaceState(state, "", window.location)
})();


