import {is_element, matches_selector} from "./element.js";

/**
 * providing events to watch for Browser DOM element creation and removal
 */
function Flow ()
{
	const arrivals = {}, departures = {}, html_elem = document.documentElement

	function walk_tree (elem, parent, bindings)
	{
		let stack = [elem];

		while (stack.length > 0) {
			elem = stack.pop();
			if (is_element(elem)) {
				for (let i in bindings[parent]) {
					const binding = bindings[parent][i]

					if (matches_selector(elem, binding.selector)) {
						binding.callback(elem)
					}
				}
			}

			stack = stack.concat(Array.prototype.slice.call(elem.childNodes, 0).reverse());
		}
	}

	const on_flow = (elem, bindings) =>
	{
		let parent = elem
		do {
			if (bindings[parent]) {
				walk_tree(elem, parent, bindings)
			}
		} while ((parent = parent.parentElement))
	}

	const on_arrive = (elem) => on_flow(elem, arrivals)
	const on_leave = (elem) => on_flow(elem, departures)

	function on_mutation (mutation)
	{
		const new_nodes = mutation.addedNodes, removed_nodes = mutation.removedNodes
		for (let i = 0, node; (node = new_nodes[i]); i++) on_arrive(node)
		for (let i = 0, node; (node = removed_nodes[i]); i++) on_leave(node)
	}

	const observer = new MutationObserver((mutations) =>
	{
		mutations.forEach(on_mutation)
	})

	observer.observe(html_elem, {
		childList: true,
		subtree: true
	})

	function bind (bindings, selector, callback)
	{
		let target = this
		let parent_elem = target.querySelectorAll ? this : html_elem
		if (!bindings[parent_elem]) {
			bindings[parent_elem] = []
		}

		bindings[parent_elem].push({selector, target, parent_elem, callback})

		return parent_elem
	}

	this.leave = function (selector, callback)
	{
		[...bind.call(this, departures, selector, callback).querySelectorAll(selector)].forEach(on_arrive)
	}

	this.arrive = function (selector, callback)
	{
		const parent = bind.call(this, arrivals, selector, callback);
		const result = parent.querySelectorAll(selector);
		([...result]).forEach(on_arrive);
	}

	return this
}

const flow = new Flow()

export function arrive (selector, callback)
{
	return flow.arrive(selector, callback)
}

export function leave (selector, callback)
{
	return flow.leave(selector, callback)
}

export const emplace_flow_api = () =>
{

	if (!HTMLElement.prototype.arrive) {
		const flow = new Flow()

		for (let [k, v] of Object.entries({HTMLElement, NodeList, HTMLCollection, Window})) {
			v.prototype.arrive = flow.arrive
			v.prototype.leave = flow.leave
		}
	}
}

emplace_flow_api()