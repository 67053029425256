export function run (tests)
{
  let failures = 0;
  for (let name in tests)
  {
    let test = tests[name];
    try
    {
      test();
      console.log('Test:', name, 'OK');
    } catch (e)
    {
      failures++;
      console.error('Test:', name, 'FAILED', e);
      console.error(e.stack);
    }
  }
}

export function assert (value, msg)
{
  if (!value)
  {
    throw new Error('assert(): ' + msg);
  }
}

assert.fail = (msg) => {
  throw new Error('fail(): ' + msg);
}

assert.eq = (expected, actual) => {
  if (expected != actual)
  {
    throw new Error('assertEquals() "' + expected + '" != "' + actual + '"');
  }
}

assert.strict_eq = (expected, actual) => {
  if (expected !== actual)
  {
    throw new Error('assertStrictEquals() "' + expected + '" !== "' + actual + '"');
  }
}

export {run as tests};