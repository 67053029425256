import {render} from "./index.js"

export function Tabs ({children = []})
{
	const tabs = children.filter((child) => child.refs.includes(Tab))

	return render`
  
    <div class="ui menu row">
      ${tabs.map((tab) => render`${tab}`)}
    </div>
  
  `
}

export function Tab (props)
{
	let {onclick, active, href} = props.attrs
	if(!onclick) {
		onclick = () => true
	}

	if(active === undefined) {
		active = false
	}

	//props.attrs

	let classes = []
	if (active) classes.push('active')
	classes = classes.join(' ')

	if (href) {
		return render`
    <a class="${classes}" onclick="${onclick}" href="${href}">${props.children}</a>
    `
	} else {
		return render`

    <div class="${classes}" onclick="${onclick}">${props.children}</div>  
    `
	}
}