import {is_string} from "../support/util.js";
import {is_html_button, parent_by_tag_name} from "./element";

const Input_type =
{
	BUTTON: 'button',
	CHECKBOX: 'checkbox',
	COLOR: 'color',
	DATE: 'date',
	DATETIME: 'datetime',
	DATETIME_LOCAL: 'datetime-local',
	EMAIL: 'email',
	FILE: 'file',
	HIDDEN: 'hidden',
	IMAGE: 'image',
	MENU: 'menu',
	MONTH: 'month',
	NUMBER: 'number',
	PASSWORD: 'password',
	RADIO: 'radio',
	RANGE: 'range',
	RESET: 'reset',
	SEARCH: 'search',
	SELECT_MULTIPLE: 'select-multiple',
	SELECT_ONE: 'select-one',
	SUBMIT: 'submit',
	TEL: 'tel',
	TEXT: 'text',
	TEXTAREA: 'textarea',
	TIME: 'time',
	URL: 'url',
	WEEK: 'week'
};

function form_data (form)
{
	return form_data_append(form, new FormData(), (data, name, val) => data.append(name, val));
}

function xhr_success (xhr)
{
	return xhr.status >= 200 && xhr.status < 400;
}

export function xhr_response_text (xhr)
{
	try {
		return xhr ? xhr.responseText : '';
	} catch (e) {
		console.log(e);
		return '';
	}
}

export function submit_form (form, http_headers, success_callback)
{
	const data = form_data(form);
	const url = form.getAttribute('action') || window.location.href;

	submit_form_data(data, url, http_headers, success_callback);
}

export function submit_form_data (data, url, http_headers, success_callback)
{
	const xhr = new XMLHttpRequest;

	xhr.open('POST', url || window.location.href);
	for (const name in http_headers) {
		xhr.setRequestHeader(name, http_headers[name])
	}

	xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");

	xhr.onreadystatechange = () => {

		console.log("onreadystatechange " + xhr.status)
		console.log(xhr.getAllResponseHeaders())
	};

	xhr.onload = () =>
	{
		if (xhr_success(xhr) && success_callback) {
			success_callback(xhr);
		}
	};

	xhr.send(data);
}

function form_data_append (form, result, append_callback)
{
	const els = form.elements;
	if (els) {
		for (let el, i = 0; (el = els[i]); ++i) {
			if (el.form !== form || el.disabled || el.tagName === 'FIELDSET') {
				continue;
			}

			const name = el.name;
			switch (el.type.toLowerCase()) {
			case Input_type.FILE:
			case Input_type.BUTTON:
			case Input_type.RESET:
			case Input_type.SUBMIT:
				break;
			default:
				const val = form_value(el);
				if (val != null) {
					append_callback(result, name, val);
				}
			}
		}
		// TODO: input[type=image] is not included in the form.elements collection
		return result;
	}

	return null;
}

function form_value_input_checked (el)
{
	return el.checked ? (el).value : null;
}

function form_value (el)
{
	const type = (el).type;
	switch (is_string(type) && type.toLowerCase()) {
	case Input_type.CHECKBOX:
	case Input_type.RADIO:
		return form_value_input_checked(el);
	case Input_type.SELECT_ONE:
		return form_value_select_one(el);
	case Input_type.SELECT_MULTIPLE:
		return form_value_select_multiple(el);
	default:
		return el.value != null ? el.value : null;
	}
}

function form_value_select_one (el)
{
	var idx = (el).selectedIndex;
	return idx >= 0 ? (el).options[idx].value : null;
}

function form_value_select_multiple (el)
{
	const values = [];
	for (let option, i = 0; (option = (el).options[i]); ++i) {
		if (option.selected) {
			values.push(option.value);
		}
	}

	return values.length ? values : null;
}

export function intercept_forms (callback)
{
	let last_clicked_button;

	function on_click (e)
	{
		if (is_html_button(e.target)) {
			last_clicked_button = e.target
		} else {
			const button = parent_by_tag_name(e.target, 'button');
			if (button) {
				last_clicked_button = button;
			}
		}
	}

	function interceptor (e)
	{
		let form = this;
		if (!(form instanceof HTMLFormElement) && e && e.target) {
			form = e.target;
		}

		let nojs = form.classList.contains('nojs') ||
			(last_clicked_button!=null && last_clicked_button.classList.contains('nojs'));

		if (!nojs) {
			if (e) {
				e.preventDefault();
			}

			callback(e, form);
		}
	}

	function submit ()
	{
		if (!this.classList.contains('nojs')) {
			interceptor.bind(this).call();
		} else {
			this._submit();
		}
	}

	HTMLFormElement.prototype._submit = HTMLFormElement.prototype.submit;
	HTMLFormElement.prototype.submit = submit
	window.addEventListener('submit', interceptor);
	window.addEventListener('click', on_click, {passive: true, capture: true});

}